require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/Freshpage/components/d1d9de3c08188cf9ff4108b1614be69a/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', [({ 'textLink': 'Home','urlLink': '#home','active': true }), ({ 'textLink': 'Features','urlLink': '#features','active': false }), ({ 'textLink': 'Testimonials','urlLink': '#testimonials','active': false }), ({ 'textLink': 'Gallery','urlLink': '#gallery','active': false }), ({ 'textLink': 'Team','urlLink': '#team','active': false }), ({ 'textLink': 'Contact','urlLink': '#contact','active': false })]);

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Freshpage/components/4494147e47463208b1e17aafe36cda16/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var textLink, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  textLink = (getObjectProperty(___arguments.context.dataModel, 'textLink'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')) == textLink) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
  ; return item;}))));
  if (textLink == 'Home') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));
  } else if (textLink == 'Features') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')));
  } else if (textLink == 'Testimonials') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')));
  } else if (textLink == 'Gallery') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')));
  } else if (textLink == 'Team') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')));
  } else if (textLink == 'Contact') {
    await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')));
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) < 990) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  }

  },
  /* handler:onClick */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['link', 'nav__link', 'nav__link_active'];
  }

  return ['link', 'nav__link']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onResize */
  async onResize(___arguments) {
    var leftSlideBy, left, j, half, galleryList, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 'auto';
  }
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) >= 1200) {
    ___arguments.context.appData['leftSlideBy'] = 240;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  half = Math.floor(galleryList.length / 2);
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && (await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && (await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['opacity'] = 1;
    j['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      j['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      j['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      j['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      j['active'] = true;
    } else {
      j['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  }

  },
  /* handler:onResize */
  /* handler:onScroll */
  async onScroll(___arguments) {
    var a, item, team, gallery, home2, features, testimonials, contact, topOnViewport, header, elem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function topOnViewport2(elem, header) {
  topOnViewport = ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', elem)) - (await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight'))) + ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) - (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', header)));
  return topOnViewport
}


  contact = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  team = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  gallery = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  testimonials = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  features = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  home2 = await topOnViewport2(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')));
  if (___arguments.scrollTop - (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) < home2) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b'))))) < features && ___arguments.scrollTop > home2) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < testimonials && ___arguments.scrollTop > features + home2) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < gallery && ___arguments.scrollTop > testimonials + features + home2) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < team && ___arguments.scrollTop > testimonials + features + home2 + gallery) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop - ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('69bb186e5a4d27787e89d891022d5497')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))))) < contact && ___arguments.scrollTop > testimonials + features + home2 + gallery + team) {
    ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d1d9de3c08188cf9ff4108b1614be69a', (await Promise.all(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('d1d9de3c08188cf9ff4108b1614be69a')).map(async item => {  if ((getObjectProperty(item, 'textLink')).toLowerCase() == (getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('0ee4f7a2f9f93096442f303878b88406')), 'anchor'))) {
        item['active'] = true;
      } else {
        item['active'] = false;
      }
    ; return item;}))));
  }
  if (___arguments.scrollTop > (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 3) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.3;
  } else if (___arguments.scrollTop > (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) / 10) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 0.5;
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('f5f6c87f71d4ee4f6b9b334b92f88f31'))['opacity'] = 1;
  }
  if (___arguments.scrollTop) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = '0px 2px 4px rgba(0, 0, 0, 0.1)';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '60px';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = '#ffffff';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#777777';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '60px';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#777777';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#777777';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#777777';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['box-shadow'] = 'none';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['height'] = '80px';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['background-color'] = 'transparent';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6'))['color'] = '#ffffff';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e7d674dfa78a31b1ef919cde1b83c4fc'))['height'] = '80px';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b45adae3392f3e5a452db72605d2a40a'))['background-color'] = '#ffffff';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('2db349952be70e19f125ec72ddd54751'))['background-color'] = '#ffffff';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('921d4565ee3f4372a1814936a1cdb964'))['background-color'] = '#ffffff';
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 400) {
    a = 150;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 500) {
    a = (typeof a == 'number' ? a : 0) + 200;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 600) {
    a = (typeof a == 'number' ? a : 0) + 300;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 700) {
    a = (typeof a == 'number' ? a : 0) + 400;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 800) {
    a = (typeof a == 'number' ? a : 0) + 500;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 900) {
    a = (typeof a == 'number' ? a : 0) + 600;
  } else if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    a = (typeof a == 'number' ? a : 0) + 700;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 990 && (await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientHeight')) <= 1050) {
    if (___arguments.scrollTop >= (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) - a)) {
      ___arguments.context.appData['featuresInFocus'] = true;
    }
    if (___arguments.scrollTop >= (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) - a)) {
      ___arguments.context.appData['testimonialsInFocus'] = true;
    }
    if (___arguments.scrollTop >= (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('480125cac29a570eaa484bf40b9f4ff6')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c69368e8222f01b7257c3dfd00b4214b')))) + (await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e0c48bb5d4f44f0bdf326a71d31123ed')))) + ((await ( async function (typeSize, component) { return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('05d6bcfb65a4cf72e36b6ec3d786124f')))) - a)) {
      ___arguments.context.appData['teamInFocus'] = true;
    }
  } else {
    ___arguments.context.appData['featuresInFocus'] = true;
    ___arguments.context.appData['testimonialsInFocus'] = true;
    ___arguments.context.appData['teamInFocus'] = true;
  }

  },
  /* handler:onScroll */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var teamList, testimonialsList, featuresList, appData, galleryList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = false;
  appData = (await (async function() {
  	const data = {
  	  "features": [
  	    {
  	      "id": "f1",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-1.jpg",
  	      "title": "Amazing Experience",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f2",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-2.jpg",
  	      "title": "24/7 Support",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f3",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-3.jpg",
  	      "title": "Real-time",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f4",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-4.jpg",
  	      "title": "Easy To Use",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f5",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-5.jpg",
  	      "title": "Free Updates",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    },
  	    {
  	      "id": "f6",
  	      "icon": "https://backendless.com/images/Fresh/dbImg/features/feature-6.jpg",
  	      "title": "All Platforms",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
  	    }
  	  ],
  	  "gallery": [
  	    {
  	      "id": "g1",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item1.jpg"
  	    },
  	    {
  	      "id": "g2",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item2.jpg"
  	    },
  	    {
  	      "id": "g3",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item3.jpg"
  	    },
  	    {
  	      "id": "g4",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item4.jpg"
  	    },
  	    {
  	      "id": "g5",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item5.jpg"
  	    },
  	    {
  	      "id": "g6",
  	      "image": "https://backendless.com/images/Fresh/dbImg/gallery/item6.jpg"
  	    }
  	  ],
  	  "team": [
  	    {
  	      "id": "t1",
  	      "name": "Joan Doe",
  	      "career": "Founder - CEO",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo1.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t2",
  	      "name": "John Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo2.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "#",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t3",
  	      "name": "Joan Doe",
  	      "career": "Co-founder",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo3.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "#",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    },
  	    {
  	      "id": "t4",
  	      "name": "John Doe",
  	      "career": "Financial Manager",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/team/photo4.jpg",
  	      "socialMedia": {
  	        "social": [
  	          {
  	            "url": "#",
  	            "name": "facebook"
  	          },
  	          {
  	            "url": "",
  	            "name": "github"
  	          },
  	          {
  	            "url": "#",
  	            "name": "linkedin"
  	          },
  	          {
  	            "url": "",
  	            "name": "twitter"
  	          }
  	        ]
  	      }
  	    }
  	  ],
  	  "testimonials": [
  	    {
  	      "id": "tm1",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth1.jpg"
  	    },
  	    {
  	      "id": "tm2",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth2.jpg"
  	    },
  	    {
  	      "id": "tm3",
  	      "author": "Jane Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/auth3.jpg"
  	    },
  	    {
  	      "id": "tm4",
  	      "author": "John Doe",
  	      "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  	      "photo": "https://backendless.com/images/Fresh/dbImg/testimonials/item4.jpg"
  	    }
  	  ]
  	}

  	return data
  })());
  featuresList = (getObjectProperty(appData, 'features'));
  galleryList = (getObjectProperty(appData, 'gallery'));
  ___arguments.context.appData['galleryList'] = galleryList;
  teamList = (getObjectProperty(appData, 'team'));
  testimonialsList = (getObjectProperty(appData, 'testimonials'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('31b2f36e83dd37c11b5e73f81d1bfac9', featuresList);
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('153bb1d13492b519dc0717b333c20947', testimonialsList);
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b6f27fbafe317fe16572dfeaf1fb0698', teamList);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/Freshpage/components/fefec6b6560f11b1a4c62ed6ad39d36c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52')), 'height')) == 349) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 0;
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('02041492d42283e8bd1d33f27a66ff52'))['height'] = 349;
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Freshpage/components/31b2f36e83dd37c11b5e73f81d1bfac9/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['featuresInFocus']) ? ['features__items-block', 'features__items-block_in-focus'] : ['features__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/153bb1d13492b519dc0717b333c20947/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['testimonialsInFocus']) ? ['testimonials__items-block', 'testimonials__items-block_in-focus'] : ['testimonials__items-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/c376c79acf1e16de2463e38439c34656/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var item, leftSlideBy, left, newGalleryList, half, firstList, galleryList, lastList, idGalleryItem;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  await new Promise(r => setTimeout(r, 1000 || 0));
  galleryList = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  idGalleryItem = 1;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(galleryList.map(async item => {  item['id'] = idGalleryItem;
    idGalleryItem = (typeof idGalleryItem == 'number' ? idGalleryItem : 0) + 1;
  ; return item;}))));
  ___arguments.context.appData['leftSlideBy'] = 240;
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 1199) {
    ___arguments.context.appData['leftSlideBy'] = 200;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 990) {
    ___arguments.context.appData['leftSlideBy'] = 260;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768) {
    ___arguments.context.appData['leftSlideBy'] = 370;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576) {
    ___arguments.context.appData['leftSlideBy'] = 250;
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.appData['leftSlideBy'] = 100;
  }
  half = Math.floor(galleryList.length / 2);
  lastList = galleryList.slice(((half + 1) - 1), galleryList.length);
  firstList = galleryList.slice(0, half);
  newGalleryList = (JSON.parse((JSON.stringify(((addItemToList(lastList, galleryList)).concat(firstList))))));
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  ___arguments.context.pageData['leftStart'] = (-15 - half * leftSlideBy);
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 768 && (await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 576) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 120);
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 576 && (await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 130);
  }
  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) <= 480) {
    ___arguments.context.pageData['leftStart'] = ((getObjectProperty(___arguments.context.pageData, 'leftStart')) + 15);
  }
  left = (getObjectProperty(___arguments.context.pageData, 'leftStart'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('c376c79acf1e16de2463e38439c34656', (await Promise.all(newGalleryList.map(async item => {  item['opacity'] = 1;
    item['left'] = left;
    if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
      item['active'] = true;
    } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
      item['active'] = true;
    } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
      item['active'] = true;
    } else if (left == 0 && leftSlideBy == 100) {
      item['active'] = true;
    } else {
      item['active'] = false;
    }
    left = (typeof left == 'number' ? left : 0) + leftSlideBy;
  ; return item;}))));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Freshpage/components/e23154e2b40dc5a8ac7f9a7e34ff980f/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  async onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((await ( async function (typeSize) { return document.documentElement[`${typeSize}`] })('clientWidth')) > 480) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('px'));
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['left'] = (String(getObjectProperty(___arguments.context.dataModel, 'left')) + String('%'));
  }
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('e23154e2b40dc5a8ac7f9a7e34ff980f'))['opacity'] = (getObjectProperty(___arguments.context.dataModel, 'opacity'));
  if (getObjectProperty(___arguments.context.dataModel, 'active')) {
    return ['g-slider__item', 'g-slider__item_center'];
  }

  return ['g-slider__item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/108f6ec347effdbce289a49d625d03af/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['isOpenModal'] = true;
  ___arguments.context.pageData['counterSlide2'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('e23154e2b40dc5a8ac7f9a7e34ff980f'), 'id'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Freshpage/components/26a1309c25831a8af67d5fe98587c773/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, minValue, card, left, j, maxValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['opacity'] = 1;
      j['left'] = ((getObjectProperty(j, 'left')) + leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (maxValue < left) {
        maxValue = left;
        card = j;
      }
      if (minValue > left) {
        minValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (minValue - leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Freshpage/components/0ecc200a34ec908bf97bef91588de587/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var leftSlideBy, maxValue, card, left, j, minValue;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  maxValue = 0;
  minValue = 0;
  leftSlideBy = (getObjectProperty(___arguments.context.appData, 'leftSlideBy'));
  if ((typeof (getObjectProperty(___arguments.context.pageData, 'stopClick')) === 'undefined') || ((new Date()).valueOf()) > (getObjectProperty(___arguments.context.pageData, 'stopClick')) + 300) {
    ___arguments.context.pageData['stopClick'] = ((new Date()).valueOf());
    var j_list = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('c376c79acf1e16de2463e38439c34656'));
    for (var j_index in j_list) {
      j = j_list[j_index];
      j['left'] = ((getObjectProperty(j, 'left')) - leftSlideBy);
      left = (getObjectProperty(j, 'left'));
      j['opacity'] = 1;
      if (left == leftSlideBy * 2 - 15 && leftSlideBy == 240 || left == leftSlideBy * 2 - 15 && leftSlideBy == 200) {
        j['active'] = true;
      } else if (left == leftSlideBy - 15 && leftSlideBy == 260) {
        j['active'] = true;
      } else if (left == 105 && leftSlideBy == 370 || left == 115 && leftSlideBy == 250) {
        j['active'] = true;
      } else if (left == 0 && leftSlideBy == 100) {
        j['active'] = true;
      } else {
        j['active'] = false;
      }
      if (minValue > left) {
        minValue = left;
        card = j;
      }
      if (maxValue < left) {
        maxValue = left;
      }
    }
    card['opacity'] = 0;
    card['left'] = (maxValue + leftSlideBy);
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Freshpage/components/4bc8650348b45a9267097b43aff505c6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('4bc8650348b45a9267097b43aff505c6', (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('e5ac9c8ad984738efc349d07b4dc74b1'), 'socialMedia')), 'social')));

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Freshpage/components/1fdde5c76ea432e90eb67a18533b2dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes, nameSocial;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['team-card__link', 'link'];
  nameSocial = (getObjectProperty(___arguments.context.dataModel, 'name'));
  if ((getObjectProperty(___arguments.context.dataModel, 'url')) == '') {
    addItemToList(classes, 'team-card__link_hide');
  }
  if (nameSocial == 'facebook') {
    addItemToList(classes, 'fab fa-facebook-f');
  }
  if (nameSocial == 'github') {
    addItemToList(classes, 'fab fa-github');
  }
  if (nameSocial == 'linkedin') {
    addItemToList(classes, 'fab fa-linkedin-in');
  }
  if (nameSocial == 'twitter') {
    addItemToList(classes, 'fab fa-twitter');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/b6f27fbafe317fe16572dfeaf1fb0698/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
      return ((___arguments.context.appData['teamInFocus']) ? ['team__cards-block', 'team__cards-block_in-focus'] : ['team__cards-block'])

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/d2551aa8d85f0b8224661c4aaaa53f32/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var firstName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (firstName.length <= 50) {
    ___arguments.context.pageData['firstNameValue'] = firstName;
  } else if (firstName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['firstName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'firstNameValue')), 0));
  }
  if ((regExp.test(firstName)) == false && firstName != '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  } else if ((regExp.test(firstName)) == false && firstName == '' || firstName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Freshpage/components/3ddf6adfe3e580cc962a62234bf2e2d5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-first-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/f5bd0a2c6f0bde798ea8df133e9e7e3c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var lastName, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  regExp = (new RegExp('.{2,}', 'g'));
  if (lastName.length <= 50) {
    ___arguments.context.pageData['lastNameValue'] = lastName;
  } else if (lastName.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['lastName'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'lastNameValue')), 0));
  }
  if ((regExp.test(lastName)) == false && lastName != '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length < 2 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  } else if ((regExp.test(lastName)) == false && lastName == '' || lastName.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Freshpage/components/557e31a13073b4848ca61837b9e70a38/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-last-name'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/cdc2459c0f1ac842997b621a04744c0c/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var email, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (email.length <= 50) {
    ___arguments.context.pageData['emailValue'] = email;
  } else if (email.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['email'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'emailValue')), 0));
  }
  if ((regExp.test(email)) == false && email != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  } else if ((regExp.test(email)) == false && email == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Freshpage/components/556317981b853c56d8e8e837e5b16c9e/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-email'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/d64247a69fb82b1b932f6a1191167d68/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-phone'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/1712009c62182acf71f1e4e777ac98dd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var phone, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  regExp = (new RegExp('^[0-9+\\(\\)\\-\\s]+$', 'g'));
  if (phone.length <= 50) {
    ___arguments.context.pageData['phoneValue'] = phone;
  } else if (phone.length > 50) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['phone'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'phoneValue')), 0));
  }
  if (phone.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length < 5 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  } else if ((regExp.test(phone)) && phone.length >= 5) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else if (phone == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Freshpage/components/275519e4f1ff2a5c0d556bd7bfaaf6c5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-website'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/ddb987ac0b1a2e02086b043645e7decd/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var website, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (website.length <= 249) {
    ___arguments.context.pageData['websiteValue'] = website;
  } else if (website.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['website'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'websiteValue')), 0));
  }
  if ((regExp.test(website)) == false && website != '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }
  if ((regExp.test((getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')))) == true && website.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website')).length) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Freshpage/components/b17c55c60f4dcdf2ae8fc66b95b63932/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var subject, regExp, firstName;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  regExp = (new RegExp('.{4,}', 'g'));
  if (subject.length <= 300) {
    ___arguments.context.pageData['subjectValue'] = subject;
  } else if (subject.length > 300) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['subject'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'subjectValue')), 0));
  }
  if ((regExp.test(subject)) == false && subject != '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length < 4 && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length != 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  } else if ((regExp.test(subject)) == false && subject == '' || subject.length != (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length && (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject')).length == 0) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Freshpage/components/a38dcbb7299151a66062e5a8eaaf984c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-subject'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject']) {
    addItemToList(classes, 'c-form__label_alert');
  } else if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject']) {
    addItemToList(classes, 'c-form__label_hint');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/101b130a0c5ff2588aa5637fc5fcdb27/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = ['c-form__label', 'c-form__label-message'];
  if (___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage']) {
    addItemToList(classes, 'c-form__label_alert');
  }

  return classes

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/6eb697a67eb600c29f860957a2ac3b3e/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, message, subject, website, phone, email, lastName, firstName, checkStatusMessage, checkStatusSubject, checkStatusWebsite, checkStatusPhone, checkStatusEmail, checkStatusLastName, checkStatusFirstName, statusMessage, statusSubject, statusWebsite, statusPhone, statusEmail, statusLastName, statusFirstName, company, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function firstNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(firstName)) || firstName == '' || firstName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusFirsName() {
  if (!(getObjectProperty(statusFirstName, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('d2551aa8d85f0b8224661c4aaaa53f32')));
    if (firstName == '' || firstName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintFirstName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertFirstName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function lastNameValidation() {
  regExp = (new RegExp('.{2,}', 'g'));
  if (!(regExp.test(lastName)) || lastName == '' || lastName == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusLastName2() {
  if (!(getObjectProperty(statusLastName, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('f5bd0a2c6f0bde798ea8df133e9e7e3c')));
    if (lastName == '' || lastName == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintLastName'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertLastName'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function emailValidation() {
  regExp = (new RegExp('[a-zA-Z0-9.\\-_+]{1,}@[^\\W\\.][a-zA-Z0-9\\-]{0,}\\.[a-zA-Z]{1,}', 'g'));
  if (!(regExp.test(email)) || email == '' || email == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function phoneValidation() {
  if (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'hintPhone')) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusEmail2() {
  if (!(getObjectProperty(statusEmail, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('cdc2459c0f1ac842997b621a04744c0c')));
    if (email == '' || email == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintEmail'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertEmail'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function websiteValidation() {
  regExp = (new RegExp('[(http(s)?):\\/\\/(www\\.)?a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)', 'gi'));
  if (!(regExp.test(website)) && website != '' && website != null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function subjectValidation() {
  regExp = (new RegExp('.{4,}', 'g'));
  if (!(regExp.test(subject)) || subject == '' || subject == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusPhone2() {
  if (!(getObjectProperty(statusPhone, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('1712009c62182acf71f1e4e777ac98dd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintPhone'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function messageValidation() {
  if (message == '' || message == null) {
    return ({ 'status': false });
  }
  return { 'status': true }
}

/**
 * Describe this function...
 */
async function checkStatusWebsite2() {
  if (!(getObjectProperty(statusWebsite, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('ddb987ac0b1a2e02086b043645e7decd')));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintWebsite'] = true;
    return false;
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusSubject2() {
  if (!(getObjectProperty(statusSubject, 'status'))) {
    await ( async function (component) { component.focus() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('b17c55c60f4dcdf2ae8fc66b95b63932')));
    if (subject == '' || subject == null) {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = false;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = true;
      return false;
    } else {
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['hintSubject'] = true;
      ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertSubject'] = false;
      return false;
    }
  }
  return true
}

/**
 * Describe this function...
 */
async function checkStatusMessage2() {
  if (!(getObjectProperty(statusMessage, 'status'))) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
    return false;
  }
  return true
}


  firstName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'firstName'));
  lastName = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'lastName'));
  email = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'email'));
  phone = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'phone'));
  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  website = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'website'));
  subject = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'subject'));
  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  statusFirstName = await firstNameValidation();
  statusLastName = await lastNameValidation();
  statusEmail = await emailValidation();
  statusPhone = await phoneValidation();
  statusWebsite = await websiteValidation();
  statusSubject = await subjectValidation();
  statusMessage = await messageValidation();
  checkStatusMessage = await checkStatusMessage2();
  checkStatusSubject = await checkStatusSubject2();
  checkStatusWebsite = await checkStatusWebsite2();
  checkStatusPhone = await checkStatusPhone2();
  checkStatusEmail = await checkStatusEmail2();
  checkStatusLastName = await checkStatusLastName2();
  checkStatusFirstName = await checkStatusFirsName();
  if (checkStatusFirstName != false && checkStatusLastName != false && checkStatusEmail != false && checkStatusPhone != false && checkStatusWebsite != false && checkStatusSubject != false && checkStatusMessage != false) {
    await Backendless.Data.of('Subscriber').save( ({ 'firstName': firstName,'lastName': lastName,'email': email,'phone': phone,'website': website,'subject': subject,'message': message }) );
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b65aa795e4d6a994b3ddd6f68533c80d', false);
    try {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', true);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', false);

    } catch (error) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('df8b067a885dcda84a91376a3c4c5c7c', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7224592bf6676a81e607676ddbc597e1', true);
      console.log(error);

    }
  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Freshpage/components/58c7b83b0c67da4ff3bef93451af2162/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var message, regExp;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  message = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'message'));
  regExp = (new RegExp('.{1,}', 'g'));
  if (message.length <= 249) {
    ___arguments.context.pageData['messageValue'] = message;
  } else if (message.length > 249) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['message'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'messageValue')), 0));
  }
  if ((regExp.test(message)) == false && message == '') {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = true;
  } else {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['alertMessage'] = false;
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Freshpage/components/dc8e2162b0174608379cfba7549be8db/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      await ( async function (component) { component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('e105ce21f5bfaabd50592f5ea611fe1b')));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Freshpage/components/251dd247af0d2f6ebe6ccde3866c89b5/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if (getObjectProperty(___arguments.context.pageData, 'isOpenModal')) {
    return ['modal', 'open'];
  }

  return ['modal']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/70dd1dff3e215deab9dcc3e29a6566e6/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  async onMounted(___arguments) {
    var counter, data, gallery, item, listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function setIdItem(data) {
  counter = 1;
  return await Promise.all(data.map(async item => {  item['id'] = counter;
  counter = (typeof counter == 'number' ? counter : 0) + 1;
; return item;}))
}


  await new Promise(r => setTimeout(r, 1000 || 0));
  gallery = (getObjectProperty(___arguments.context.appData, 'galleryList'));
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', (await setIdItem(gallery)));
  listLength = gallery.length;
  ___arguments.context.pageData['totalSlide'] = listLength;
  ___arguments.context.pageData['currentSlide'] = 1;

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/Freshpage/components/bb37f7327e62a50eb06507a61d6d1fc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == (getObjectProperty(___arguments.context.dataModel, 'id'))) {
    return ['modal__slider-item', 'active'];
  }

  return ['modal__slider-item']

  },
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/Freshpage/components/831fddd4bb7ced9296de1aa550213577/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) > 1) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1 ? listLength : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) - 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == 1) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('831fddd4bb7ced9296de1aa550213577'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/Freshpage/components/dba21793ecfc81f1424a1ce6ad99a8b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) < listLength) {
    ___arguments.context.pageData['counterSlide2'] = ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength ? 1 : (getObjectProperty(___arguments.context.pageData, 'counterSlide2')) + 1);
  }
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ___arguments.context.pageData['currentSlide'] = (getObjectProperty(___arguments.context.pageData, 'counterSlide2'));

  },
  /* handler:onClick */
  /* handler:onMouseOut */
  onMouseOut(___arguments) {
    var listLength;


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';

  },
  /* handler:onMouseOut */
  /* handler:onMouseOver */
  onMouseOver(___arguments) {
    var listLength;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  listLength = ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')).length;
  ;(function (componentUid, listItems){ ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e39c22968faa4695d8e898f20f6f1cee', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems })('e39c22968faa4695d8e898f20f6f1cee')));
  if ((getObjectProperty(___arguments.context.pageData, 'counterSlide2')) == listLength) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '0';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('dba21793ecfc81f1424a1ce6ad99a8b3'))['opacity'] = '1';
  }

  },
  /* handler:onMouseOver */
  /* content */
}))

define('./pages/Freshpage/components/31c87ba294176e587b91b87c58e68a82/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Freshpage/components/16b9cb3fdc9bf1919465a18c77a6e467/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.pageData['isOpenModal'] = false;

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/Freshpage/components/3607af0d8419be43455fc4ac16f9cc33/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  async onChange(___arguments) {
    var company;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function subsequenceFirstFromEnd(sequence, at2) {
  var start = 0;
  var end = sequence.length - 1 - at2 + 1;
  return sequence.slice(start, end);
}


  company = (getObjectProperty(___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d'), 'company'));
  if (company.length <= 100) {
    ___arguments.context.pageData['companyValue'] = company;
  } else if (company.length > 100) {
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = ' ';
    await new Promise(r => setTimeout(r, 1 || 0));
    ___arguments.context.getComponentDataStoreByUid('30ea90dd05af67628fd821d5003c3d3d')['company'] = (subsequenceFirstFromEnd((getObjectProperty(___arguments.context.pageData, 'companyValue')), 0));
  }

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/Freshpage/components/30ea90dd05af67628fd821d5003c3d3d/bundle.js', [], () => ({
  /* content */
  /* handler:onMounted */
  onMounted(___arguments) {
      ___arguments.context.pageData['firstNameValue'] = '';
  ___arguments.context.pageData['lastNameValue'] = '';
  ___arguments.context.pageData['emailValue'] = '';
  ___arguments.context.pageData['phoneValue'] = '';
  ___arguments.context.pageData['companyValue'] = '';
  ___arguments.context.pageData['websiteValue'] = '';
  ___arguments.context.pageData['subjectValue'] = '';
  ___arguments.context.pageData['messageValue'] = '';

  },
  /* handler:onMounted */
  /* content */
}))

define('./pages/about/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var contactInfo, item, courses;


  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('d5b2d5ab8f0e2bb7c196596ebefe66b5'))['display'] = 'flex';
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e4f7ffddadbb4e0cbd75c231b49847fc', (await Backendless.Data.of('About').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10))));
  ___arguments.context.pageData['btnlabel'] = 'Subscribe';
  courses = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  ___arguments.context.appData['popularCourses'] = (await (async () => {
    async function callback (item) {


      return (item['isFeatured']);
    }

    const list = await Promise.all(courses.map(async source => ({
      source,
      value: await callback(source),
    })));

    const resultList = list.filter(item => item.value)

    return resultList.map(item => item.source)
  })());
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e75db1f7f7ac6149137c8b9a8aefdd77', (___arguments.context.appData['popularCourses']));
  contactInfo = (await Backendless.Data.of('ContactInfo').find(Backendless.DataQueryBuilder.create().setPageSize(10)))[0];
  ___arguments.context.pageData['address'] = (contactInfo['address']);
  ___arguments.context.pageData['phone'] = (contactInfo['phone']);
  ___arguments.context.pageData['contactEmail'] = (contactInfo['email']);
  ___arguments.context.pageData['socialLinks'] = (contactInfo['socialLinks']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('aa966a8dbbca954dc512805e3efbb25d', (___arguments.context.pageData['socialLinks']));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('d5b2d5ab8f0e2bb7c196596ebefe66b5'))['display'] = 'none';

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/about/components/84f907bfbdd124929071a7b7db42cb35/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      if (((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open']) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = false;
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = true;
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/fa66b3d71af418b734b703c72c7b3895/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('home', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/8feeb67a8526a9610161413cdcee27ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, userEmail;


  userEmail = (___arguments.context.pageData['email']);
  if (!userEmail) {
    ___arguments.context.pageData['message'] = 'Please enter your email';
    await new Promise(r => setTimeout(r, 250 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
    await new Promise(r => setTimeout(r, 2000 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    return false;
  }
  try {
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = true;
    ___arguments.context.pageData['btnlabel'] = 'Sending...';
    await Backendless.Data.of('Subscriber').save( ({ 'email': userEmail }) );
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8feeb67a8526a9610161413cdcee27ff', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ba74516dcece599c4cda9cdfb0e48b9', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('074c75b20d76e225b092fbbee555a627', true);

  } catch (error) {
    console.log(error);
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = false;
    ___arguments.context.pageData['btnlabel'] = 'Subscribe';
    if ((error['code']) == 8023) {
      ___arguments.context.pageData['message'] = 'Email address is not valid, please correct the email address and try again';
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
      await new Promise(r => setTimeout(r, 3500 || 0));
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    }

  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/96beb1e8c39f1ce10f673445106c7808/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.dataModel['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/9955e9238106ee628c22981e49fe222a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('mailto:') + String(___arguments.context.pageData['email'])), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/89fb35af24fe1da141df86e926182bc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var phoneToCall, i, phone;


  phone = (___arguments.context.pageData['phone']);
  phoneToCall = '';
  var i_end = phone.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (phone[(i - 1)] != '-') {
      phoneToCall = phoneToCall + phone[(i - 1)];
    }
  }
  ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('tel:') + String(phoneToCall)), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/66d57075806ba64b37726d5600df2092/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((___arguments.context.dataModel['link']), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/about/components/6b263ab24803022b6b53c81fcb8f58c9/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  onTargetPathAssignment(___arguments) {
      return (String('https://maps.google.com/?q=') + String(___arguments.context.pageData['address']))

  },  
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/allCourses/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var contactInfo, item, courses;


  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b883f97fb326273e622201ed7e29ce51'))['display'] = 'flex';
  courses = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  ___arguments.context.appData['popularCourses'] = (await (async () => {
    async function callback (item) {


      return (item['isFeatured']);
    }

    const list = await Promise.all(courses.map(async source => ({
      source,
      value: await callback(source),
    })));

    const resultList = list.filter(item => item.value)

    return resultList.map(item => item.source)
  })());
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d12239b29d062f6f9d73d30d8d5f6598', (___arguments.context.appData['popularCourses']));
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('7da6a3533ceaaaa12bd91fbb0f09110a', (await (async () => {
    async function callback (item) {


      return (!(item['isFeatured']));
    }

    const list = await Promise.all(courses.map(async source => ({
      source,
      value: await callback(source),
    })));

    const resultList = list.filter(item => item.value)

    return resultList.map(item => item.source)
  })()));
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e75db1f7f7ac6149137c8b9a8aefdd77', (___arguments.context.appData['popularCourses']));
  ___arguments.context.pageData['btnlabel'] = 'Subscribe';
  contactInfo = (await Backendless.Data.of('ContactInfo').find(Backendless.DataQueryBuilder.create().setPageSize(10)))[0];
  ___arguments.context.pageData['address'] = (contactInfo['address']);
  ___arguments.context.pageData['phone'] = (contactInfo['phone']);
  ___arguments.context.pageData['contactEmail'] = (contactInfo['email']);
  ___arguments.context.pageData['socialLinks'] = (contactInfo['socialLinks']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('aa966a8dbbca954dc512805e3efbb25d', (___arguments.context.pageData['socialLinks']));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b883f97fb326273e622201ed7e29ce51'))['display'] = 'none';

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/allCourses/components/6e8fd96497c55daf92b43161e71d2bf2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.getComponentDataStoreByUid('57455e38cb3fc31bc9a5e4e301b5bf4f')['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/allCourses/components/75f579b354f842472361c7a448110c1f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.getComponentDataStoreByUid('57455e38cb3fc31bc9a5e4e301b5bf4f')['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/allCourses/components/4d84f01604de5877cc5cd4ee9a2ec189/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.getComponentDataStoreByUid('11352d71156cfc67803729bb562b5af8')['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/allCourses/components/db7db59397aa4057ea4b70efd5d8fea5/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.getComponentDataStoreByUid('11352d71156cfc67803729bb562b5af8')['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/allCourses/components/8feeb67a8526a9610161413cdcee27ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, userEmail;


  userEmail = (___arguments.context.pageData['email']);
  if (!userEmail) {
    ___arguments.context.pageData['message'] = 'Please enter your email';
    await new Promise(r => setTimeout(r, 250 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
    await new Promise(r => setTimeout(r, 2000 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    return false;
  }
  try {
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = true;
    ___arguments.context.pageData['btnlabel'] = 'Sending...';
    await Backendless.Data.of('Subscriber').save( ({ 'email': userEmail }) );
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8feeb67a8526a9610161413cdcee27ff', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ba74516dcece599c4cda9cdfb0e48b9', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('074c75b20d76e225b092fbbee555a627', true);

  } catch (error) {
    console.log(error);
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = false;
    ___arguments.context.pageData['btnlabel'] = 'Subscribe';
    if ((error['code']) == 8023) {
      ___arguments.context.pageData['message'] = 'Email address is not valid, please correct the email address and try again';
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
      await new Promise(r => setTimeout(r, 3500 || 0));
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    }

  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/allCourses/components/96beb1e8c39f1ce10f673445106c7808/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.dataModel['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/allCourses/components/89fb35af24fe1da141df86e926182bc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var phoneToCall, i, phone;


  phone = (___arguments.context.pageData['phone']);
  phoneToCall = '';
  var i_end = phone.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (phone[(i - 1)] != '-') {
      phoneToCall = phoneToCall + phone[(i - 1)];
    }
  }
  ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('tel:') + String(phoneToCall)), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/allCourses/components/9955e9238106ee628c22981e49fe222a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('mailto:') + String(___arguments.context.pageData['email'])), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/allCourses/components/84f907bfbdd124929071a7b7db42cb35/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      if (((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open']) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = false;
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = true;
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/allCourses/components/fa66b3d71af418b734b703c72c7b3895/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('home', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/allCourses/components/21d409b9e03c68ef951a4758e2e8447c/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((___arguments.context.dataModel['link']), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/allCourses/components/6b263ab24803022b6b53c81fcb8f58c9/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  onTargetPathAssignment(___arguments) {
      return (String('https://maps.google.com/?q=') + String(___arguments.context.pageData['address']))

  },  
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/contact/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var item, courses, contactInfo;


  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b43cbdd96e255845c55fde5298192b9b'))['display'] = 'flex';
  ___arguments.context.pageData['btnlabel'] = 'Subscribe';
  ((function (componentUid){ return ___arguments.context.getComponentDataStoreByUid(componentUid) })('8e0952d75abca735aab215fd18174a99'))['sendBtn'] = 'Send message';
  contactInfo = (await Backendless.Data.of('ContactInfo').find(Backendless.DataQueryBuilder.create().setPageSize(10)))[0];
  ___arguments.context.pageData['address'] = (contactInfo['address']);
  ___arguments.context.pageData['phone'] = (contactInfo['phone']);
  ___arguments.context.pageData['contactEmail'] = (contactInfo['email']);
  ___arguments.context.pageData['socialLinks'] = (contactInfo['socialLinks']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('b0b3f819974e32d6af9a24d29e0d43ee', (___arguments.context.pageData['socialLinks']));
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('aa966a8dbbca954dc512805e3efbb25d', (___arguments.context.pageData['socialLinks']));
  courses = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  ___arguments.context.appData['popularCourses'] = (await (async () => {
    async function callback (item) {


      return (item['isFeatured']);
    }

    const list = await Promise.all(courses.map(async source => ({
      source,
      value: await callback(source),
    })));

    const resultList = list.filter(item => item.value)

    return resultList.map(item => item.source)
  })());
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e75db1f7f7ac6149137c8b9a8aefdd77', (___arguments.context.appData['popularCourses']));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b43cbdd96e255845c55fde5298192b9b'))['display'] = 'none';

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/contact/components/9b24d75ad9b83d31db6be272435ef41f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, isValid, list;


  isValid = true;
  if (!(___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['name'])) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ca03c02628f1c5c9746adf0f93bd53b8', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b91b57a7520d615614f705470301607b'))['border'] = '1px solid #cc0000';
    isValid = false;
  }
  if (!(___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['email'])) {
    ___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['emailError'] = 'This field is required.';
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4ddc6bde610d3208ae2bcb8a31d7c154', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('62d9f577ea0665ada102b10c476a52c9'))['border'] = '1px solid #cc0000';
    isValid = false;
  } else {
    if (!((/\b[a-z0-9._]+@[a-z0-9.-]+\.[a-z]{2,4}\b/i).test((___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['email'])))) {
      ___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['emailError'] = 'Please enter a valid email address.';
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4ddc6bde610d3208ae2bcb8a31d7c154', true);
      isValid = false;
    }
  }
  if (!(___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['subject'])) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8f07ecb6c68d79deb091769db59622f5', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('4fdda8e7a988af2e34f69517acbfff35'))['border'] = '1px solid #cc0000';
    isValid = false;
  }
  if (!(___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['message'])) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3b9848f8e7da8b81e717eed8e1348051', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('c26c1ff4ee5ebde77f5cc4bda1f5b872'))['border'] = '1px solid #cc0000';
    isValid = false;
  }
  if (isValid) {
    try {
      ___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['sendBtn'] = 'Sending...';
      await Backendless.Data.of('Inquiry').save( ({ 'name': (___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['name']),'email': (___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['email']),'subject': (___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['subject']),'message': (___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['message']) }) );
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8e0952d75abca735aab215fd18174a99', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4a37abca0f73e97d58b57667fed41e9c', true);
      ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('cf7d14c237d930328765606ed20b66ec'))['justify-content'] = 'center';
      ___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['sendBtn'] = 'Send Message';

    } catch (error) {
      ___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['sendBtn'] = 'Send Message';
      ;await ( async function (message){ alert(message) })((String('Something went wrong. Server returned: ') + String(error)));

    }
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/b91b57a7520d615614f705470301607b/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  onChange(___arguments) {
      if (!(___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['name'])) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ca03c02628f1c5c9746adf0f93bd53b8', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b91b57a7520d615614f705470301607b'))['border'] = '1px solid #cc0000';
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('ca03c02628f1c5c9746adf0f93bd53b8', false);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('b91b57a7520d615614f705470301607b'))['border'] = '1px solid #eaeaea';
  }

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/contact/components/62d9f577ea0665ada102b10c476a52c9/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  onChange(___arguments) {
      if (!(___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['email'])) {
    ___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['emailError'] = 'This field is required.';
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4ddc6bde610d3208ae2bcb8a31d7c154', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('62d9f577ea0665ada102b10c476a52c9'))['border'] = '1px solid #cc0000';
  } else {
    if (!((/\b[a-z0-9._]+@[a-z0-9.-]+\.[a-z]{2,4}\b/i).test((___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['email'])))) {
      ___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['emailError'] = 'Please enter a valid email address.';
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4ddc6bde610d3208ae2bcb8a31d7c154', true);
      ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('62d9f577ea0665ada102b10c476a52c9'))['border'] = '1px solid #cc0000';
    } else {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('4ddc6bde610d3208ae2bcb8a31d7c154', false);
      ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('62d9f577ea0665ada102b10c476a52c9'))['border'] = '1px solid #eaeaea';
    }
  }

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/contact/components/4fdda8e7a988af2e34f69517acbfff35/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  onChange(___arguments) {
      if (!(___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['subject'])) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8f07ecb6c68d79deb091769db59622f5', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('4fdda8e7a988af2e34f69517acbfff35'))['border'] = '1px solid #cc0000';
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8f07ecb6c68d79deb091769db59622f5', false);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('4fdda8e7a988af2e34f69517acbfff35'))['border'] = '1px solid #eaeaea';
  }

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/contact/components/c26c1ff4ee5ebde77f5cc4bda1f5b872/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  onChange(___arguments) {
      if (!(___arguments.context.getComponentDataStoreByUid('8e0952d75abca735aab215fd18174a99')['message'])) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3b9848f8e7da8b81e717eed8e1348051', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('c26c1ff4ee5ebde77f5cc4bda1f5b872'))['border'] = '1px solid #cc0000';
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('3b9848f8e7da8b81e717eed8e1348051', false);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('c26c1ff4ee5ebde77f5cc4bda1f5b872'))['border'] = '1px solid #eaeaea';
  }

  },  
  /* handler:onChange */
  /* content */
}))

define('./pages/contact/components/84f907bfbdd124929071a7b7db42cb35/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      if (((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open']) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = false;
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = true;
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/fa66b3d71af418b734b703c72c7b3895/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('home', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/96beb1e8c39f1ce10f673445106c7808/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.dataModel['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/9955e9238106ee628c22981e49fe222a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('mailto:') + String(___arguments.context.pageData['email'])), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/89fb35af24fe1da141df86e926182bc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var phoneToCall, i, phone;


  phone = (___arguments.context.pageData['phone']);
  phoneToCall = '';
  var i_end = phone.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (phone[(i - 1)] != '-') {
      phoneToCall = phoneToCall + phone[(i - 1)];
    }
  }
  ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('tel:') + String(phoneToCall)), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/8feeb67a8526a9610161413cdcee27ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, userEmail;


  userEmail = (___arguments.context.pageData['email']);
  if (!userEmail) {
    ___arguments.context.pageData['message'] = 'Please enter your email';
    await new Promise(r => setTimeout(r, 250 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
    await new Promise(r => setTimeout(r, 2000 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    return false;
  }
  try {
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = true;
    ___arguments.context.pageData['btnlabel'] = 'Sending...';
    await Backendless.Data.of('Subscriber').save( ({ 'email': userEmail }) );
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8feeb67a8526a9610161413cdcee27ff', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ba74516dcece599c4cda9cdfb0e48b9', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('074c75b20d76e225b092fbbee555a627', true);

  } catch (error) {
    console.log(error);
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = false;
    ___arguments.context.pageData['btnlabel'] = 'Subscribe';
    if ((error['code']) == 8023) {
      ___arguments.context.pageData['message'] = 'Email address is not valid, please correct the email address and try again';
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
      await new Promise(r => setTimeout(r, 3500 || 0));
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    }

  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/905220c4e96c6d75706841759dd199b3/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const queryString = BackendlessUI.QueryString.stringify(params); const targetUrl = `${url}${queryString ? '?' + queryString : ''}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('https://maps.google.com/?q=') + String(___arguments.context.pageData['address'])), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/24375deee0b8764249eb2d12bd967b7f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var phoneToCall, i, phone;


  phone = (___arguments.context.pageData['phone']);
  phoneToCall = '';
  var i_end = phone.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (phone[(i - 1)] != '-') {
      phoneToCall = phoneToCall + phone[(i - 1)];
    }
  }
  ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('tel:') + String(phoneToCall)), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/624a7e3a950224e1a68ea2c607e3a6ec/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('mailto:') + String(___arguments.context.pageData['email'])), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/32d8a71da03e222c9b52e4e5933c1dba/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((___arguments.context.dataModel['link']), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/aa966a8dbbca954dc512805e3efbb25d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    
  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/cfc184fcd5a92c5ee59b14e75523f5ca/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((___arguments.context.dataModel['link']), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/contact/components/6b263ab24803022b6b53c81fcb8f58c9/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  onTargetPathAssignment(___arguments) {
      return (String('https://maps.google.com/?q=') + String(___arguments.context.pageData['address']))

  },  
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/contact/components/ebcc519fd81a3d49e64877b28fd94032/bundle.js', [], () => ({
  /* content */
  /* handler:onMapCenterAssignment */
  onMapCenterAssignment(___arguments) {
    var coord;


  coord = ({ 'lat': 33.07772813175916,'lng': -96.82346235978943 });

  return coord

  },  
  /* handler:onMapCenterAssignment */
  /* handler:onPointsAssignment */
  onPointsAssignment(___arguments) {
    var coord;


  coord = [({ 'lat': 33.07772813175916,'lng': -96.82346235978943 })];

  return coord

  },  
  /* handler:onPointsAssignment */
  /* content */
}))

define('./pages/course/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var contactInfo, item, courses, course, j, ID;

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}


  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('ea7c95f28e52f50bb97a7b7a5061bbcf'))['display'] = 'flex';
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('bb3ca51a45a9b51ed9bc544f02adfe7c')));
  ___arguments.context.pageData['btnlabel'] = 'Subscribe';
  ID = (___arguments.context.pageData['ID']);
  course = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',ID,'\''].join(''))).setRelated('lessons').setRelationsDepth(2).setPageSize(10)));
  (course[0])['lessons'] = (await (async () => {
    async function callback (item) {


      return (item['order']);
    }

    const list = await Promise.all(((course[0])['lessons']).map(async source => ({
      source,
      value: await callback(source),
    })));

    sortBy(list, 1);

    return list.map(item => item.source)
  })());
  var j_list = ((course[0])['lessons']);
  for (var j_index in j_list) {
    j = j_list[j_index];
    j['topics'] = (await (async () => {
      async function callback (item) {


        return (item['order']);
      }

      const list = await Promise.all((j['topics']).map(async source => ({
        source,
        value: await callback(source),
      })));

      sortBy(list, 1);

      return list.map(item => item.source)
    })());
  }
  (course[0])['keyconcepts'] = (await Promise.all(((course[0])['keyconcepts']).map(async item => {; return ({ 'key': item });})));
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('09e5a20d5bc5b13a7f0cfe42ae7e8b3d', course);
  courses = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  ___arguments.context.appData['popularCourses'] = (await (async () => {
    async function callback (item) {


      return (item['isFeatured']);
    }

    const list = await Promise.all(courses.map(async source => ({
      source,
      value: await callback(source),
    })));

    const resultList = list.filter(item => item.value)

    return resultList.map(item => item.source)
  })());
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e75db1f7f7ac6149137c8b9a8aefdd77', (___arguments.context.appData['popularCourses']));
  contactInfo = (await Backendless.Data.of('ContactInfo').find(Backendless.DataQueryBuilder.create().setPageSize(10)))[0];
  ___arguments.context.pageData['address'] = (contactInfo['address']);
  ___arguments.context.pageData['phone'] = (contactInfo['phone']);
  ___arguments.context.pageData['contactEmail'] = (contactInfo['email']);
  ___arguments.context.pageData['socialLinks'] = (contactInfo['socialLinks']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('aa966a8dbbca954dc512805e3efbb25d', (___arguments.context.pageData['socialLinks']));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('ea7c95f28e52f50bb97a7b7a5061bbcf'))['display'] = 'none';

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/course/components/84f907bfbdd124929071a7b7db42cb35/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      if (((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open']) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = false;
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = true;
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/course/components/fa66b3d71af418b734b703c72c7b3895/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('home', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/course/components/c4d6447cf4a50a42089f8fb67d1ec07f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var i, heightContent;


  if (!(((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('b2a5d1ce9ebd48dbe7dccf4878858ce9'))['1'])) {
    ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('b2a5d1ce9ebd48dbe7dccf4878858ce9'))['1'] = 'course__expended';
    ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('533676e20acd98d75ecee1df1821b3a5'))['1'] = 'course__expend-all-title-exp';
    var i_end = ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('729406e76ea46b8762451c875e6960d7'))['el'])['children']).length;
    var i_inc = 1;
    if (1 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      heightContent = 100 + ((((((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('729406e76ea46b8762451c875e6960d7'))['el'])['children'])[(i - 1)])['children'])[1])['children'])[1])['clientHeight']);
      ((((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('729406e76ea46b8762451c875e6960d7'))['el'])['children'])[(i - 1)])['children'])[1])['style'])['maxHeight'] = (String(heightContent) + String('px'));
    }
    var i_end2 = ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('729406e76ea46b8762451c875e6960d7'))['el'])['children']).length;
    var i_inc2 = 1;
    if (1 > i_end2) {
      i_inc2 = -i_inc2;
    }
    for (i = 1; i_inc2 >= 0 ? i <= i_end2 : i >= i_end2; i += i_inc2) {
      ((((((((((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('729406e76ea46b8762451c875e6960d7'))['el'])['children'])[(i - 1)])['children'])[0])['children'])[0])['children'])[1])['children'])[0])['classList'])['value'] = 'material-icons MuiIcon-root accordion__arrow-btn accordion__arrow-btn-exp  bl-icon material-icons-round';
      ((((((((((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('729406e76ea46b8762451c875e6960d7'))['el'])['children'])[(i - 1)])['children'])[0])['children'])[0])['children'])[1])['children'])[1])['classList'])['value'] = 'accordion__lable-btn accordion__lable-btn-exp bl-text';
    }
  } else {
    delete ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('b2a5d1ce9ebd48dbe7dccf4878858ce9'))['1'];
    delete ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('533676e20acd98d75ecee1df1821b3a5'))['1'];
    var i_end3 = ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('729406e76ea46b8762451c875e6960d7'))['el'])['children']).length;
    var i_inc3 = 1;
    if (1 > i_end3) {
      i_inc3 = -i_inc3;
    }
    for (i = 1; i_inc3 >= 0 ? i <= i_end3 : i >= i_end3; i += i_inc3) {
      ((((((((((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('729406e76ea46b8762451c875e6960d7'))['el'])['children'])[(i - 1)])['children'])[0])['children'])[0])['children'])[1])['children'])[0])['classList'])['value'] = 'material-icons MuiIcon-root accordion__arrow-btn bl-icon material-icons-round';
      ((((((((((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('729406e76ea46b8762451c875e6960d7'))['el'])['children'])[(i - 1)])['children'])[0])['children'])[0])['children'])[1])['children'])[1])['classList'])['value'] = 'accordion__lable-btn bl-text';
    }
    var i_end4 = ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('729406e76ea46b8762451c875e6960d7'))['el'])['children']).length;
    var i_inc4 = 1;
    if (1 > i_end4) {
      i_inc4 = -i_inc4;
    }
    for (i = 1; i_inc4 >= 0 ? i <= i_end4 : i >= i_end4; i += i_inc4) {
      ((((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('729406e76ea46b8762451c875e6960d7'))['el'])['children'])[(i - 1)])['children'])[1])['style'])['maxHeight'] = (String(0) + String('px'));
    }
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/course/components/8feeb67a8526a9610161413cdcee27ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, userEmail;


  userEmail = (___arguments.context.pageData['email']);
  if (!userEmail) {
    ___arguments.context.pageData['message'] = 'Please enter your email';
    await new Promise(r => setTimeout(r, 250 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
    await new Promise(r => setTimeout(r, 2000 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    return false;
  }
  try {
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = true;
    ___arguments.context.pageData['btnlabel'] = 'Sending...';
    await Backendless.Data.of('Subscriber').save( ({ 'email': userEmail }) );
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8feeb67a8526a9610161413cdcee27ff', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ba74516dcece599c4cda9cdfb0e48b9', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('074c75b20d76e225b092fbbee555a627', true);

  } catch (error) {
    console.log(error);
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = false;
    ___arguments.context.pageData['btnlabel'] = 'Subscribe';
    if ((error['code']) == 8023) {
      ___arguments.context.pageData['message'] = 'Email address is not valid, please correct the email address and try again';
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
      await new Promise(r => setTimeout(r, 3500 || 0));
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    }

  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/course/components/447864c84f1105f98d457bb52d4dc4d6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var heightContent;


  heightContent = 100 + (await ( async function (typeSize, component){ return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('821efe1a2ab1b97a9a56946e39f4722c'))));
  if ((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('f1a33eb5064e6bb3d2cc8f08006df1f7'))['el'])['classList'])['value']).indexOf('accordion__arrow-btn-exp') + 1 == 0) {
    ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('f1a33eb5064e6bb3d2cc8f08006df1f7'))['el'])['classList'])['value'] = 'material-icons MuiIcon-root accordion__arrow-btn accordion__arrow-btn-exp  bl-icon material-icons-round';
    ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('224971df6349fd06a8a49e874e137f4d'))['el'])['classList'])['value'] = 'accordion__lable-btn accordion__lable-btn-exp bl-text';
    ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('b5ff6ab8e6dc6fed5637aa46bda42f93'))['el'])['style'])['maxHeight'] = (String(heightContent) + String('px'));
  } else {
    ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('f1a33eb5064e6bb3d2cc8f08006df1f7'))['el'])['classList'])['value'] = 'material-icons MuiIcon-root accordion__arrow-btn bl-icon material-icons-round';
    ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('224971df6349fd06a8a49e874e137f4d'))['el'])['classList'])['value'] = 'accordion__lable-btn bl-text';
    ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('b5ff6ab8e6dc6fed5637aa46bda42f93'))['el'])['style'])['maxHeight'] = (String(0) + String('px'));
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/course/components/0d0abaca50e6ae6e7771db4f8a652a7a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.getComponentDataStoreByUid('9bd150741c23f09875699b350bbca904')['objectId']),'lessonID': (___arguments.context.getComponentDataStoreByUid('3f3f93676cd1ab890846583f31cbe9f3')['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/course/components/ab352a0affdeab7a74f7b22ae2afded9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.getComponentDataStoreByUid('9bd150741c23f09875699b350bbca904')['objectId']),'lessonID': (___arguments.context.getComponentDataStoreByUid('3f3f93676cd1ab890846583f31cbe9f3')['objectId']),'topicID': (___arguments.context.dataModel['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/course/components/96beb1e8c39f1ce10f673445106c7808/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var course, item;


  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.dataModel['objectId']) }));
  course = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(___arguments.context.dataModel['objectId']),'\''].join(''))).setRelated('lessons').setRelationsDepth(2).setPageSize(10)));
  (course[0])['keyconcepts'] = (((course[0])['keyconcepts']).map(item => {; return ({ 'key': item });}));
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('09e5a20d5bc5b13a7f0cfe42ae7e8b3d', course);
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('bb3ca51a45a9b51ed9bc544f02adfe7c')));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/course/components/9955e9238106ee628c22981e49fe222a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('mailto:') + String(___arguments.context.pageData['email'])), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/course/components/89fb35af24fe1da141df86e926182bc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var phoneToCall, i, phone;


  phone = (___arguments.context.pageData['phone']);
  phoneToCall = '';
  var i_end = phone.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (phone[(i - 1)] != '-') {
      phoneToCall = phoneToCall + phone[(i - 1)];
    }
  }
  ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('tel:') + String(phoneToCall)), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/course/components/c87dbc53950f3264d9df290684a7836f/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((___arguments.context.dataModel['link']), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/course/components/6b263ab24803022b6b53c81fcb8f58c9/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  onTargetPathAssignment(___arguments) {
      return (String('https://maps.google.com/?q=') + String(___arguments.context.pageData['address']))

  },  
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/course/components/21ba19c5d0880912c8c3611c9d0f055d/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    var totalSteps;


  totalSteps = String((___arguments.context.getComponentDataStoreByUid('3f3f93676cd1ab890846583f31cbe9f3')['topics']).length) + String(' Steps');
  if ((___arguments.context.getComponentDataStoreByUid('3f3f93676cd1ab890846583f31cbe9f3')['topics']).length == 1) {
    totalSteps = String((___arguments.context.getComponentDataStoreByUid('3f3f93676cd1ab890846583f31cbe9f3')['topics']).length) + String(' Step');
  }

  return totalSteps

  },  
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/course/components/361f943df65dd22ff1bd77fe9795d156/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  onContentAssignment(___arguments) {
    var topicName;


  topicName = String((___arguments.context.getComponentDataStoreByUid('3f3f93676cd1ab890846583f31cbe9f3')['topics']).length) + String(' Topics');
  if ((___arguments.context.getComponentDataStoreByUid('3f3f93676cd1ab890846583f31cbe9f3')['topics']).length == 1) {
    topicName = String((___arguments.context.getComponentDataStoreByUid('3f3f93676cd1ab890846583f31cbe9f3')['topics']).length) + String(' Topic');
  }

  return topicName

  },  
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/home/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var testimonialsCards, starsNumber, j, contactInfo, item, courses;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

async function asyncListFilter(sourceList, callback) {
  const list = await Promise.all(sourceList.map(async source => ({
    source,
    value: await callback(source),
  })));

  const resultList = list.filter(item => item.value)

  return resultList.map(item => item.source)
}

function listsRepeat(value, n) {
  var array = [];
  for (var i = 0; i < n; i++) {
    array[i] = value;
  }
  return array;
}


  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ac4a977832adf413fc821584ff0ad028'))['display'] = 'flex';
  ___arguments.context.pageData['btnlabel'] = 'Subscribe';
  courses = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  ___arguments.context.appData['popularCourses'] = (await asyncListFilter(courses, async (item) => {


   return (getObjectProperty(item, 'isFeatured'));
  }));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('d12239b29d062f6f9d73d30d8d5f6598', (getObjectProperty(___arguments.context.appData, 'popularCourses')));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e75db1f7f7ac6149137c8b9a8aefdd77', (getObjectProperty(___arguments.context.appData, 'popularCourses')));
  contactInfo = (await Backendless.Data.of('ContactInfo').find(Backendless.DataQueryBuilder.create().setPageSize(10)))[0];
  ___arguments.context.pageData['address'] = (getObjectProperty(contactInfo, 'address'));
  ___arguments.context.pageData['phone'] = (getObjectProperty(contactInfo, 'phone'));
  ___arguments.context.pageData['contactEmail'] = (getObjectProperty(contactInfo, 'email'));
  ___arguments.context.pageData['socialLinks'] = (getObjectProperty(contactInfo, 'socialLinks'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('aa966a8dbbca954dc512805e3efbb25d', (getObjectProperty(___arguments.context.pageData, 'socialLinks')));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('bbe89b03620fb3da8b461f16dfc31c31', (await Backendless.Data.of('Feature').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10))));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('07becbf39e93e62f6cc9df59330b7d45', (await Backendless.Data.of('Technology').find(Backendless.DataQueryBuilder.create().setPageSize(10))));
  testimonialsCards = (await Backendless.Data.of('Testimonial').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  for (var j_index in testimonialsCards) {
    j = testimonialsCards[j_index];
    starsNumber = (getObjectProperty(j, 'rating'));
    if (starsNumber > 5) {
      starsNumber = 5;
    }
    j['star'] = (listsRepeat(0, starsNumber));
  }
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('fb8d504246919b5a0c961eba1dacffe2', testimonialsCards);
  ___arguments.context.pageData['counter'] = '0.0';
  ___arguments.context.pageData['isStarted'] = false;
  ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('ac4a977832adf413fc821584ff0ad028'))['display'] = 'none';

  },
  /* handler:onEnter */
  /* handler:onScroll */
  async onScroll(___arguments) {
    var secondValue, firstValue, dot;

function defineGlobalScope() { const root = (typeof window !== 'undefined' ? window : global);root.codelessScope = root.codelessScope || {};return root.codelessScope;}

function getGlobalEntitiesMap(mapName) { const globalScope = defineGlobalScope();return globalScope[mapName] = globalScope[mapName] || {};}

function stopSetTimeout(timerId) {  const timers = getGlobalEntitiesMap('setIntervals'); if (timerId && timers[timerId]) {    clearInterval(timers[timerId]);    delete timers[timerId]; }}

function runSetTimeout(timerId, callback, delay) {  const timers = getGlobalEntitiesMap('setIntervals'); const timer = setInterval(callback, delay); if (timerId) {  stopSetTimeout(timerId);  timers[timerId] = timer }}


  if (___arguments.scrollTop > ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('2418c4eb5e0915dfeafc7a27056c7a32'))['el'])['offsetTop']) - (await ( async function (typeSize){ return document.documentElement[`${typeSize}`] })('clientHeight'))) {
    if (!(___arguments.context.pageData['isStarted'])) {
      ___arguments.context.pageData['isStarted'] = true;
      firstValue = 0;
      secondValue = -1;
      dot = '.';

      ;(function() {
        const callback = async () => {
            secondValue = (typeof secondValue == 'number' ? secondValue : 0) + 1;
        if (secondValue == 10) {
          secondValue = 0;
          firstValue = (typeof firstValue == 'number' ? firstValue : 0) + 1;
        }
        ___arguments.context.pageData['counter'] = (firstValue + dot + secondValue);
        if (firstValue == 4 && secondValue == 8) {
          stopSetTimeout('count');
        }

        };

        const timerId = 'count';
        const timerDelay = 35;

        runSetTimeout(timerId, callback, timerDelay)
      })()
    }
  }

  },  
  /* handler:onScroll */
  /* content */
}))

define('./pages/home/components/fae18ef3ccb1b9d21698e72cb2f89c96/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    
  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/8feeb67a8526a9610161413cdcee27ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var error, userEmail;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  userEmail = (getObjectProperty(___arguments.context.pageData, 'email'));
  if (!userEmail) {
    ___arguments.context.pageData['message'] = 'Please enter your email';
    await new Promise(r => setTimeout(r, 250 || 0));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
    await new Promise(r => setTimeout(r, 2000 || 0));
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    return false;
  }
  try {
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = true;
    ___arguments.context.pageData['btnlabel'] = 'Sending...';
    await Backendless.Data.of('Subscriber').save( ({ [`email`]: userEmail }) );
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8feeb67a8526a9610161413cdcee27ff', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ba74516dcece599c4cda9cdfb0e48b9', false);
    (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('074c75b20d76e225b092fbbee555a627', true);

  } catch (error) {
    console.log(error);
    ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = false;
    ___arguments.context.pageData['btnlabel'] = 'Subscribe';
    if ((getObjectProperty(error, 'code')) == 8023) {
      ___arguments.context.pageData['message'] = 'Email address is not valid, please correct the email address and try again';
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
      await new Promise(r => setTimeout(r, 3500 || 0));
      (function (componentUid, visible) { (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    }

  }

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/6e8fd96497c55daf92b43161e71d2bf2/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.getComponentDataStoreByUid('57455e38cb3fc31bc9a5e4e301b5bf4f')['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/2091ca7b810f7fddf57d8c570df70c9b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.getComponentDataStoreByUid('57455e38cb3fc31bc9a5e4e301b5bf4f')['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/96beb1e8c39f1ce10f673445106c7808/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.dataModel['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/9955e9238106ee628c22981e49fe222a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('mailto:') + String(___arguments.context.pageData['email'])), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/89fb35af24fe1da141df86e926182bc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var phoneToCall, i, phone;


  phone = (___arguments.context.pageData['phone']);
  phoneToCall = '';
  var i_end = phone.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (phone[(i - 1)] != '-') {
      phoneToCall = phoneToCall + phone[(i - 1)];
    }
  }
  ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('tel:') + String(phoneToCall)), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/7aa5176c3aa5b0c3e997eb210dc2cac9/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('allCourses', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/84f907bfbdd124929071a7b7db42cb35/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      if (((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open']) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = false;
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = true;
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/fa66b3d71af418b734b703c72c7b3895/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/c39e9a22636901cad06585751042c92b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('allCourses', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/ade753e641625aeb7c6c595a9b02f6a1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((___arguments.context.dataModel['link']), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/6b263ab24803022b6b53c81fcb8f58c9/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  onTargetPathAssignment(___arguments) {
      return (String('https://maps.google.com/?q=') + String(___arguments.context.pageData['address']))

  },  
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/home/components/8d91f9c228dc8b3f931894ca4e5b1e18/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/home/components/bd598d5462d5bf55d43d98e7a3c87adc/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('privacypolicy', undefined);

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/home/components/6c99fd6345d79bce6882b0bccca7b9dc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('login', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/instructors/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var contactInfo, item, courses;


  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('47215882bb1511b7a32b3632c6961ee9'))['display'] = 'flex';
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('377efe09da7c5a04c1ee7ef10fdd35bf', (await Promise.all((await Backendless.Data.of('Instructor').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10))).map(async item => {  item['specialty'] = (String(item['specialty']) + String(' Instructor'));
  ; return item;}))));
  ___arguments.context.pageData['btnlabel'] = 'Subscribe';
  courses = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  ___arguments.context.appData['popularCourses'] = (await (async () => {
    async function callback (item) {


      return (item['isFeatured']);
    }

    const list = await Promise.all(courses.map(async source => ({
      source,
      value: await callback(source),
    })));

    const resultList = list.filter(item => item.value)

    return resultList.map(item => item.source)
  })());
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e75db1f7f7ac6149137c8b9a8aefdd77', (___arguments.context.appData['popularCourses']));
  contactInfo = (await Backendless.Data.of('ContactInfo').find(Backendless.DataQueryBuilder.create().setPageSize(10)))[0];
  ___arguments.context.pageData['address'] = (contactInfo['address']);
  ___arguments.context.pageData['phone'] = (contactInfo['phone']);
  ___arguments.context.pageData['contactEmail'] = (contactInfo['email']);
  ___arguments.context.pageData['socialLinks'] = (contactInfo['socialLinks']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('aa966a8dbbca954dc512805e3efbb25d', (___arguments.context.pageData['socialLinks']));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('47215882bb1511b7a32b3632c6961ee9'))['display'] = 'none';

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/instructors/components/84f907bfbdd124929071a7b7db42cb35/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      if (((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open']) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = false;
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = true;
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/instructors/components/fa66b3d71af418b734b703c72c7b3895/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('home', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/instructors/components/8feeb67a8526a9610161413cdcee27ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, userEmail;


  userEmail = (___arguments.context.pageData['email']);
  if (!userEmail) {
    ___arguments.context.pageData['message'] = 'Please enter your email';
    await new Promise(r => setTimeout(r, 250 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
    await new Promise(r => setTimeout(r, 2000 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    return false;
  }
  try {
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = true;
    ___arguments.context.pageData['btnlabel'] = 'Sending...';
    await Backendless.Data.of('Subscriber').save( ({ 'email': userEmail }) );
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8feeb67a8526a9610161413cdcee27ff', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ba74516dcece599c4cda9cdfb0e48b9', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('074c75b20d76e225b092fbbee555a627', true);

  } catch (error) {
    console.log(error);
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = false;
    ___arguments.context.pageData['btnlabel'] = 'Subscribe';
    if ((error['code']) == 8023) {
      ___arguments.context.pageData['message'] = 'Email address is not valid, please correct the email address and try again';
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
      await new Promise(r => setTimeout(r, 3500 || 0));
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    }

  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/instructors/components/96beb1e8c39f1ce10f673445106c7808/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.dataModel['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/instructors/components/9955e9238106ee628c22981e49fe222a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('mailto:') + String(___arguments.context.pageData['email'])), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/instructors/components/89fb35af24fe1da141df86e926182bc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var phoneToCall, i, phone;


  phone = (___arguments.context.pageData['phone']);
  phoneToCall = '';
  var i_end = phone.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (phone[(i - 1)] != '-') {
      phoneToCall = phoneToCall + phone[(i - 1)];
    }
  }
  ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('tel:') + String(phoneToCall)), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/instructors/components/bd94bfb7b765202a6041b93309cd9dac/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((___arguments.context.dataModel['link']), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/instructors/components/6b263ab24803022b6b53c81fcb8f58c9/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  onTargetPathAssignment(___arguments) {
      return (String('https://maps.google.com/?q=') + String(___arguments.context.pageData['address']))

  },  
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/lesson/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var lessons, currentLesson, currentHost, currentTopics, currentTopic, i, topicID, course, lessonID, item, j, courseID;

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}


  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('c9c4371a16a149638c764122b7259c6a'))['display'] = 'flex';
  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
  courseID = (___arguments.context.pageData['courseID']);
  lessonID = (___arguments.context.pageData['lessonID']);
  topicID = (___arguments.context.pageData['topicID']);
  course = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',courseID,'\''].join(''))).setRelated('lessons').setRelationsDepth(2).setPageSize(10)))[0];
  lessons = (await (async () => {
    async function callback (item) {


      return (item['order']);
    }

    const list = await Promise.all((course['lessons']).map(async source => ({
      source,
      value: await callback(source),
    })));

    sortBy(list, 1);

    return list.map(item => item.source)
  })());
  for (var j_index in lessons) {
    j = lessons[j_index];
    j['topics'] = (await (async () => {
      async function callback (item) {


        return (item['order']);
      }

      const list = await Promise.all((j['topics']).map(async source => ({
        source,
        value: await callback(source),
      })));

      sortBy(list, 1);

      return list.map(item => item.source)
    })());
  }
  var i_end = lessons.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (lessonID == ((lessons[(i - 1)])['objectId'])) {
      ___arguments.context.appData['currentLessonIndex'] = i;
      currentLesson = lessons[(i - 1)];
    }
    if (((lessons[(i - 1)])['topics']).length > 1) {
      (lessons[(i - 1)])['topicsNumber'] = (String(((lessons[(i - 1)])['topics']).length) + String(' Topics'));
    } else {
      (lessons[(i - 1)])['topicsNumber'] = (String(((lessons[(i - 1)])['topics']).length) + String(' Topic'));
    }
  }
  ___arguments.context.appData['lessons'] = lessons;
  currentTopics = (currentLesson['topics']);
  ___arguments.context.appData['currentTopics'] = currentTopics;
  ___arguments.context.pageData['courseTitle'] = (course['title']);
  ___arguments.context.pageData['breadcrumCourse'] = (course['title']);
  ___arguments.context.pageData['breadcrumLesson'] = (currentLesson['title']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('0c4b81392d343d9b911e7c6726f3833c', (___arguments.context.appData['lessons']));
  if (topicID) {
    var i_end2 = currentTopics.length;
    var i_inc2 = 1;
    if (1 > i_end2) {
      i_inc2 = -i_inc2;
    }
    for (i = 1; i_inc2 >= 0 ? i <= i_end2 : i >= i_end2; i += i_inc2) {
      if (topicID == ((currentTopics[(i - 1)])['objectId'])) {
        ___arguments.context.appData['currentTopicIndex'] = i;
        currentTopic = currentTopics[(i - 1)];
      }
    }
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', true);
    if ((___arguments.context.appData['currentTopicIndex']) == 1) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
    }
    if ((___arguments.context.appData['currentTopicIndex']) == currentTopics.length) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
    }
    if ((___arguments.context.appData['currentTopicIndex']) > 1 && (___arguments.context.appData['currentTopicIndex']) < currentTopics.length) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    }
    if (currentTopics.length == 1) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
    }
    ___arguments.context.pageData['title'] = (currentTopic['title']);
    ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(currentTopic['content'])))).setEncoding('utf8').send());
    ___arguments.context.pageData['breadcrumTopic'] = (currentTopic['title']);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('1e11b59f66bb729a015a88390da344d3'))['display'] = 'none';
  } else {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('79a723c60b11c79594a7b053bdefcb5a'))['display'] = 'none';
    ___arguments.context.pageData['title'] = (currentLesson['title']);
    ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('8abbfa1639bdb9040f2f743c46e8a3d4', currentTopics);
    ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(currentLesson['content'])))).setEncoding('utf8').send());
  }
  if ((___arguments.context.appData['currentLessonIndex']) == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == lessons.length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  ___arguments.context.appData['expendLesson'] = false;
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('c9c4371a16a149638c764122b7259c6a'))['display'] = 'none';

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/lesson/components/bbd4f14b1b42199cb1462fc8c7855ea8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.pageData['courseID']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/61a19ac581d8e251b27a718f3f63a2d7/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.pageData['courseID']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/e66835680cc2eed2379964fdab71fc52/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var screenWidth, currentTopics, indexNextLesson, nextLesson, currentHost, currentLessonIndex;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('1e11b59f66bb729a015a88390da344d3'))['display'] = 'flex';
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('79a723c60b11c79594a7b053bdefcb5a'))['display'] = 'none';
  ___arguments.context.appData['expendLesson'] = false;
  currentLessonIndex = (___arguments.context.appData['currentLessonIndex']);
  indexNextLesson = currentLessonIndex + 1;
  nextLesson = (___arguments.context.appData['lessons'])[(indexNextLesson - 1)];
  ___arguments.context.pageData['title'] = (nextLesson['title']);
  ___arguments.context.pageData['breadcrumLesson'] = (nextLesson['title']);
  currentTopics = (nextLesson['topics']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('8abbfa1639bdb9040f2f743c46e8a3d4', currentTopics);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(nextLesson['content'])))).setEncoding('utf8').send());
  if (indexNextLesson == (___arguments.context.appData['lessons']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', true);
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (nextLesson['objectId']) }));
  ___arguments.context.appData['expendLesson'] = false;
  ___arguments.context.appData['currentLessonIndex'] = indexNextLesson;
  ___arguments.context.appData['currentTopics'] = currentTopics;
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c15ea329091e4e0e1a2af4fda4c72068')));
  screenWidth = ((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('a9c1f1af5c7c7f750d466e430f7d2b3b'))['el'])['ownerDocument'])['documentElement'])['clientWidth']);
  if (screenWidth <= 768) {
    ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('9f42b85af289996de39f5475fd53833d')));
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/f6df59a13d38913b7352dc0ed321c4bd/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var screenWidth, currentTopics, indexPreviousLesson, previousLesson, currentHost;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('1e11b59f66bb729a015a88390da344d3'))['display'] = 'flex';
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('79a723c60b11c79594a7b053bdefcb5a'))['display'] = 'none';
  ___arguments.context.appData['expendLesson'] = false;
  indexPreviousLesson = (___arguments.context.appData['currentLessonIndex']) - 1;
  previousLesson = (___arguments.context.appData['lessons'])[(indexPreviousLesson - 1)];
  ___arguments.context.pageData['title'] = (previousLesson['title']);
  ___arguments.context.pageData['breadcrumLesson'] = (previousLesson['title']);
  currentTopics = (previousLesson['topics']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('8abbfa1639bdb9040f2f743c46e8a3d4', currentTopics);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(previousLesson['content'])))).setEncoding('utf8').send());
  if (indexPreviousLesson == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', true);
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (previousLesson['objectId']) }));
  ___arguments.context.appData['currentLessonIndex'] = indexPreviousLesson;
  ___arguments.context.appData['currentTopics'] = currentTopics;
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c15ea329091e4e0e1a2af4fda4c72068')));
  screenWidth = ((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('a9c1f1af5c7c7f750d466e430f7d2b3b'))['el'])['ownerDocument'])['documentElement'])['clientWidth']);
  if (screenWidth <= 768) {
    ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('9f42b85af289996de39f5475fd53833d')));
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/a9c1f1af5c7c7f750d466e430f7d2b3b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
    var screenWidth, item;


  screenWidth = ((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('a9c1f1af5c7c7f750d466e430f7d2b3b'))['el'])['ownerDocument'])['documentElement'])['clientWidth']);
  if (screenWidth < 961) {
    if (!((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('99e9eb7a9c013f433a3864dca62d13d2'))[1]) {
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('99e9eb7a9c013f433a3864dca62d13d2'))[1] = 'aside--expended';
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('d44429151e74bbdfe1493fef32651307'))[1] = 'aside__icon-arrow--expended';
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('0c4b81392d343d9b911e7c6726f3833c'))[1] = 'aside__main--expended';
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('77e4f4182b1408f3b60d8dfa805bcd6d'))[1] = 'main-lesson--expended';
    } else {
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('99e9eb7a9c013f433a3864dca62d13d2')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('d44429151e74bbdfe1493fef32651307')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('0c4b81392d343d9b911e7c6726f3833c')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('77e4f4182b1408f3b60d8dfa805bcd6d')).splice(1, 1);
    }
  } else {
    if (!((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('99e9eb7a9c013f433a3864dca62d13d2'))[1]) {
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('99e9eb7a9c013f433a3864dca62d13d2'))[1] = 'aside--collapsed';
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('d44429151e74bbdfe1493fef32651307'))[1] = 'aside__icon-arrow--collapsed';
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('0c4b81392d343d9b911e7c6726f3833c'))[1] = 'aside__main--collapsed';
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('77e4f4182b1408f3b60d8dfa805bcd6d'))[1] = 'main-lesson--collapsed';
    } else {
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('99e9eb7a9c013f433a3864dca62d13d2')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('d44429151e74bbdfe1493fef32651307')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('0c4b81392d343d9b911e7c6726f3833c')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('77e4f4182b1408f3b60d8dfa805bcd6d')).splice(1, 1);
    }
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/4f120ae4c7826d2916d684e69316029a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ___arguments.context.appData['expendLesson'] = (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['objectId']);
  ___arguments.context.appData['isOpen'] = (!(___arguments.context.appData['isOpen']));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/08b22c6565241ea9f68b3f6c41854512/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function listsRepeat(value, n) {
  var array = [];
  for (var i = 0; i < n; i++) {
    array[i] = value;
  }
  return array;
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = listsRepeat('aside__lesson-title', 1);
  if ((___arguments.context.pageData['lessonID']) == (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['objectId'])) {
    classes = (addItemToList(classes, 'aside__lesson-title--active'));
  }

  return classes

  },  
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lesson/components/06c9a08cba7aa73c26dfef7a89e116be/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function listsRepeat(value, n) {
  var array = [];
  for (var i = 0; i < n; i++) {
    array[i] = value;
  }
  return array;
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = listsRepeat('aside__topic-circle', 1);
  if ((___arguments.context.pageData['lessonID']) == (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['objectId'])) {
    classes = (addItemToList(classes, 'aside__topic-circle--active'));
  }

  return classes

  },  
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lesson/components/a108e2c11e235f6283f82aa467e30f9c/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var classes;

function listsRepeat(value, n) {
  var array = [];
  for (var i = 0; i < n; i++) {
    array[i] = value;
  }
  return array;
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  classes = listsRepeat('aside__topics-wrapper', 1);
  if ((___arguments.context.pageData['lessonID']) == (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['objectId'])) {
    classes = (addItemToList(classes, 'aside__topics-wrapper--active'));
  }

  return classes

  },  
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lesson/components/c9e765f92c028be25a783ad657771019/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  async onClassListAssignment(___arguments) {
    var classes, heightContent;

function listsRepeat(value, n) {
  var array = [];
  for (var i = 0; i < n; i++) {
    array[i] = value;
  }
  return array;
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  heightContent = (await ( async function (typeSize, component){ return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('255772d7f0f8228862c3c87c2133a363'))));
  classes = listsRepeat('aside__arrow-topics', 1);
  if (!(___arguments.context.appData['expendLesson'])) {
    if ((___arguments.context.pageData['lessonID']) == (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['objectId'])) {
      classes = ['aside__arrow-topics', 'aside__arrow-topics--exp'];
      ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('a108e2c11e235f6283f82aa467e30f9c'))['maxHeight'] = (String(heightContent) + String('px'));
    } else {
      ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('a108e2c11e235f6283f82aa467e30f9c'))['maxHeight'] = 0;
    }
  }
  if ((___arguments.context.appData['expendLesson']) == (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['objectId'])) {
    classes = listsRepeat('aside__arrow-topics', 1);
    if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('c9e765f92c028be25a783ad657771019'))[1]) {
      ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('a108e2c11e235f6283f82aa467e30f9c'))['maxHeight'] = 0;
      classes.splice(1, 1);
    } else {
      ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('a108e2c11e235f6283f82aa467e30f9c'))['maxHeight'] = (String(heightContent) + String('px'));
      classes = (addItemToList(classes, 'aside__arrow-topics--exp'));
    }
  } else {
    if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('c9e765f92c028be25a783ad657771019'))[1] && (___arguments.context.appData['expendLesson'])) {
      classes = (addItemToList(classes, 'aside__arrow-topics--exp'));
    }
  }

  return classes

  },  
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lesson/components/58b280c8c1a7d4c24d9950d3e84b457b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.pageData['courseID']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/6d9b09db937db8cbb7ba586e29dc70f8/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var screenWidth, currentTopics, indexNextLesson, currentHost, i;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  screenWidth = ((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('a9c1f1af5c7c7f750d466e430f7d2b3b'))['el'])['ownerDocument'])['documentElement'])['clientWidth']);
  if (screenWidth < 961) {
    if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('99e9eb7a9c013f433a3864dca62d13d2'))[1]) {
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('99e9eb7a9c013f433a3864dca62d13d2')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('d44429151e74bbdfe1493fef32651307')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('0c4b81392d343d9b911e7c6726f3833c')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('77e4f4182b1408f3b60d8dfa805bcd6d')).splice(1, 1);
    }
  }
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
  ___arguments.context.appData['expendLesson'] = false;
  var i_end = (___arguments.context.appData['lessons']).length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if ((___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['objectId']) == (((___arguments.context.appData['lessons'])[(i - 1)])['objectId'])) {
      indexNextLesson = i;
    }
  }
  ___arguments.context.pageData['breadcrumLesson'] = (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['title']);
  ___arguments.context.pageData['title'] = (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['title']);
  currentTopics = (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['topics']);
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('1e11b59f66bb729a015a88390da344d3'))['display'] = 'flex';
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('8abbfa1639bdb9040f2f743c46e8a3d4', currentTopics);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['content'])))).setEncoding('utf8').send());
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('79a723c60b11c79594a7b053bdefcb5a'))['display'] = 'none';
  if ((___arguments.context.appData['lessons']).length != 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', true);
    if (indexNextLesson == (___arguments.context.appData['lessons']).length) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
    }
    if (indexNextLesson == 1) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
    }
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['objectId']) }));
  ___arguments.context.appData['expendLesson'] = false;
  ___arguments.context.appData['currentLessonIndex'] = indexNextLesson;
  ___arguments.context.appData['currentTopics'] = currentTopics;
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c15ea329091e4e0e1a2af4fda4c72068')));
  if (screenWidth <= 768) {
    ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('9f42b85af289996de39f5475fd53833d')));
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/e7fbc502988cf30c9a1cdaf4373dfb7b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var currentTopics, indexNextLesson, nextLesson, currentHost, currentLessonIndex;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('1e11b59f66bb729a015a88390da344d3'))['display'] = 'flex';
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('79a723c60b11c79594a7b053bdefcb5a'))['display'] = 'none';
  ___arguments.context.appData['expendLesson'] = false;
  currentLessonIndex = (___arguments.context.appData['currentLessonIndex']);
  indexNextLesson = currentLessonIndex + 1;
  nextLesson = (___arguments.context.appData['lessons'])[(indexNextLesson - 1)];
  ___arguments.context.pageData['title'] = (nextLesson['title']);
  ___arguments.context.pageData['breadcrumLesson'] = (nextLesson['title']);
  currentTopics = (nextLesson['topics']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('8abbfa1639bdb9040f2f743c46e8a3d4', currentTopics);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(nextLesson['content'])))).setEncoding('utf8').send());
  if (indexNextLesson == (___arguments.context.appData['lessons']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', true);
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (nextLesson['objectId']) }));
  ___arguments.context.appData['expendLesson'] = false;
  ___arguments.context.appData['currentLessonIndex'] = indexNextLesson;
  ___arguments.context.appData['currentTopics'] = currentTopics;
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('51468ffb55571e86e2eea32f32bd7238')));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/0aabd368706547f3965185ab7bb8ad71/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var currentTopics, indexPreviousLesson, previousLesson, currentHost;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('1e11b59f66bb729a015a88390da344d3'))['display'] = 'flex';
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('79a723c60b11c79594a7b053bdefcb5a'))['display'] = 'none';
  ___arguments.context.appData['expendLesson'] = false;
  indexPreviousLesson = (___arguments.context.appData['currentLessonIndex']) - 1;
  previousLesson = (___arguments.context.appData['lessons'])[(indexPreviousLesson - 1)];
  ___arguments.context.pageData['title'] = (previousLesson['title']);
  ___arguments.context.pageData['breadcrumLesson'] = (previousLesson['title']);
  currentTopics = (previousLesson['topics']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('8abbfa1639bdb9040f2f743c46e8a3d4', currentTopics);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(previousLesson['content'])))).setEncoding('utf8').send());
  if (indexPreviousLesson == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', true);
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (previousLesson['objectId']) }));
  ___arguments.context.appData['currentLessonIndex'] = indexPreviousLesson;
  ___arguments.context.appData['currentTopics'] = currentTopics;
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('51468ffb55571e86e2eea32f32bd7238')));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/09e459cc3b2a91464b45ea2fa48edbfa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('home', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/d566449f6804bcfca5ff84f9426ab5aa/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('99e9eb7a9c013f433a3864dca62d13d2'))[1] = 'aside--expended';
  ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('d44429151e74bbdfe1493fef32651307'))[1] = 'aside__icon-arrow--expended';
  ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('0c4b81392d343d9b911e7c6726f3833c'))[1] = 'aside__main--expended';

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/86fe6c97ded1b41439569413332d1eea/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var screenWidth, currentLesson, currentHost, lessons, i;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  screenWidth = ((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('a9c1f1af5c7c7f750d466e430f7d2b3b'))['el'])['ownerDocument'])['documentElement'])['clientWidth']);
  if (screenWidth < 961) {
    if (((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('99e9eb7a9c013f433a3864dca62d13d2'))[1]) {
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('99e9eb7a9c013f433a3864dca62d13d2')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('d44429151e74bbdfe1493fef32651307')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('0c4b81392d343d9b911e7c6726f3833c')).splice(1, 1);
      ((function (componentUid){ return ___arguments.context.getComponentClassesByUid(componentUid) })('77e4f4182b1408f3b60d8dfa805bcd6d')).splice(1, 1);
    }
  }
  lessons = (___arguments.context.appData['lessons']);
  var i_end = lessons.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if ((___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['objectId']) == ((lessons[(i - 1)])['objectId'])) {
      ___arguments.context.appData['currentLessonIndex'] = i;
      currentLesson = lessons[(i - 1)];
    }
  }
  ___arguments.context.appData['currentTopics'] = (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['topics']);
  var i_end2 = (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['topics']).length;
  var i_inc2 = 1;
  if (1 > i_end2) {
    i_inc2 = -i_inc2;
  }
  for (i = 1; i_inc2 >= 0 ? i <= i_end2 : i >= i_end2; i += i_inc2) {
    if ((___arguments.context.dataModel['objectId']) == (((___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['topics'])[(i - 1)])['objectId'])) {
      ___arguments.context.appData['currentTopicIndex'] = i;
    }
  }
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', true);
  if ((___arguments.context.appData['currentTopicIndex']) == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  if ((___arguments.context.appData['currentTopicIndex']) == (___arguments.context.appData['currentTopics']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  if ((___arguments.context.appData['currentTopics']).length == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
  }
  if ((___arguments.context.appData['currentTopicIndex']) > 1 && (___arguments.context.appData['currentTopicIndex']) < (___arguments.context.appData['currentTopics']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == lessons.length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  ___arguments.context.pageData['title'] = (___arguments.context.dataModel['title']);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(___arguments.context.dataModel['content'])))).setEncoding('utf8').send());
  ___arguments.context.pageData['breadcrumLesson'] = (currentLesson['title']);
  ___arguments.context.pageData['breadcrumTopic'] = (___arguments.context.dataModel['title']);
  if (!(___arguments.context.pageData['topicID'])) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('1e11b59f66bb729a015a88390da344d3'))['display'] = 'none';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('79a723c60b11c79594a7b053bdefcb5a'))['display'] = 'inline';
  }
  ___arguments.context.pageData['lessonID'] = (___arguments.context.getComponentDataStoreByUid('e1c6a74686fcd8431ba946ec84047269')['objectId']);
  ___arguments.context.appData['expendLesson'] = false;
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (___arguments.context.pageData['lessonID']),'topicID': (___arguments.context.dataModel['objectId']) }));
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c15ea329091e4e0e1a2af4fda4c72068')));
  if (screenWidth <= 768) {
    ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('9f42b85af289996de39f5475fd53833d')));
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/9b13a14f1a46c13d92e909278bec416a/bundle.js', [], () => ({
  /* content */
  /* handler:onClassListAssignment */
  onClassListAssignment(___arguments) {
    var topicClasses;


  if ((___arguments.context.pageData['topicID']) == (___arguments.context.getComponentDataStoreByUid('86fe6c97ded1b41439569413332d1eea')['objectId'])) {
    topicClasses = ['aside__topic-title', 'aside__topic-title--active'];
  } else {
    topicClasses = ['aside__topic-title'];
  }

  return topicClasses

  },  
  /* handler:onClassListAssignment */
  /* content */
}))

define('./pages/lesson/components/ddda74696309aeae6074ff7643ad285b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var screenWidth, currentHost, i;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  var i_end = (___arguments.context.appData['currentTopics']).length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if ((___arguments.context.dataModel['objectId']) == (((___arguments.context.appData['currentTopics'])[(i - 1)])['objectId'])) {
      ___arguments.context.appData['currentTopicIndex'] = i;
    }
  }
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', true);
  if ((___arguments.context.appData['currentTopicIndex']) == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  if ((___arguments.context.appData['currentTopicIndex']) == (___arguments.context.appData['currentTopics']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  if ((___arguments.context.appData['currentTopics']).length == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  if ((___arguments.context.appData['currentTopicIndex']) > 1 && (___arguments.context.appData['currentTopicIndex']) < (___arguments.context.appData['currentTopics']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == (___arguments.context.appData['lessons']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  ___arguments.context.pageData['title'] = (___arguments.context.dataModel['title']);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(___arguments.context.dataModel['content'])))).setEncoding('utf8').send());
  ___arguments.context.pageData['breadcrumTopic'] = (___arguments.context.dataModel['title']);
  if (!(___arguments.context.pageData['topicID'])) {
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('1e11b59f66bb729a015a88390da344d3'))['display'] = 'none';
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('79a723c60b11c79594a7b053bdefcb5a'))['display'] = 'inline';
  }
  ___arguments.context.appData['expendLesson'] = false;
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (___arguments.context.pageData['lessonID']),'topicID': (___arguments.context.dataModel['objectId']) }));
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c15ea329091e4e0e1a2af4fda4c72068')));
  screenWidth = ((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('a9c1f1af5c7c7f750d466e430f7d2b3b'))['el'])['ownerDocument'])['documentElement'])['clientWidth']);
  if (screenWidth <= 768) {
    ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('9f42b85af289996de39f5475fd53833d')));
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/490c6504f58aa91aa7076ccb4538187a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var screenWidth, nextTopicIndex, nextTopic, currentHost, currentTopicIndex;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  currentTopicIndex = (___arguments.context.appData['currentTopicIndex']);
  nextTopicIndex = currentTopicIndex + 1;
  nextTopic = (___arguments.context.appData['currentTopics'])[(nextTopicIndex - 1)];
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', true);
  if (nextTopicIndex == (___arguments.context.appData['currentTopics']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == (___arguments.context.appData['lessons']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  ___arguments.context.pageData['title'] = (nextTopic['title']);
  ___arguments.context.pageData['breadcrumTopic'] = (nextTopic['title']);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(nextTopic['content'])))).setEncoding('utf8').send());
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (___arguments.context.pageData['lessonID']),'topicID': (nextTopic['objectId']) }));
  ___arguments.context.appData['expendLesson'] = false;
  ___arguments.context.appData['currentTopicIndex'] = nextTopicIndex;
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c15ea329091e4e0e1a2af4fda4c72068')));
  screenWidth = ((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('a9c1f1af5c7c7f750d466e430f7d2b3b'))['el'])['ownerDocument'])['documentElement'])['clientWidth']);
  if (screenWidth <= 768) {
    ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('9f42b85af289996de39f5475fd53833d')));
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/a602de667a6632644c1d38128d8f5679/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var screenWidth, previousTopicIndex, nextTopic, currentHost, currentTopicIndex;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  currentTopicIndex = (___arguments.context.appData['currentTopicIndex']);
  previousTopicIndex = currentTopicIndex - 1;
  nextTopic = (___arguments.context.appData['currentTopics'])[(previousTopicIndex - 1)];
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', true);
  if (previousTopicIndex == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == (___arguments.context.appData['lessons']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  ___arguments.context.pageData['title'] = (nextTopic['title']);
  ___arguments.context.pageData['breadcrumTopic'] = (nextTopic['title']);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(nextTopic['content'])))).setEncoding('utf8').send());
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (___arguments.context.pageData['lessonID']),'topicID': (nextTopic['objectId']) }));
  ___arguments.context.appData['expendLesson'] = false;
  ___arguments.context.appData['currentTopicIndex'] = previousTopicIndex;
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c15ea329091e4e0e1a2af4fda4c72068')));
  screenWidth = ((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('a9c1f1af5c7c7f750d466e430f7d2b3b'))['el'])['ownerDocument'])['documentElement'])['clientWidth']);
  if (screenWidth <= 768) {
    ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('9f42b85af289996de39f5475fd53833d')));
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/ff36bedad8b31c790d68a54f64550d66/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var screenWidth, currentTopics, currentLesson, currentHost;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
  ___arguments.context.appData['expendLesson'] = false;
  currentLesson = (___arguments.context.appData['lessons'])[((___arguments.context.appData['currentLessonIndex']) - 1)];
  ___arguments.context.pageData['breadcrumLesson'] = (currentLesson['title']);
  ___arguments.context.pageData['title'] = (currentLesson['title']);
  currentTopics = (currentLesson['topics']);
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('1e11b59f66bb729a015a88390da344d3'))['display'] = 'flex';
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('8abbfa1639bdb9040f2f743c46e8a3d4', currentTopics);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(currentLesson['content'])))).setEncoding('utf8').send());
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('79a723c60b11c79594a7b053bdefcb5a'))['display'] = 'none';
  if ((___arguments.context.appData['lessons']).length != 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', true);
    if ((___arguments.context.appData['currentLessonIndex']) == (___arguments.context.appData['lessons']).length) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
    }
    if ((___arguments.context.appData['currentLessonIndex']) == 1) {
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
    }
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (currentLesson['objectId']) }));
  ___arguments.context.appData['expendLesson'] = false;
  ___arguments.context.appData['currentTopics'] = currentTopics;
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('c15ea329091e4e0e1a2af4fda4c72068')));
  screenWidth = ((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('a9c1f1af5c7c7f750d466e430f7d2b3b'))['el'])['ownerDocument'])['documentElement'])['clientWidth']);
  if (screenWidth < 768) {
    ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('9f42b85af289996de39f5475fd53833d')));
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/7b173a522ab61e0c780737640d3fc90b/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var nextTopicIndex, nextTopic, currentHost, currentTopicIndex;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  currentTopicIndex = (___arguments.context.appData['currentTopicIndex']);
  nextTopicIndex = currentTopicIndex + 1;
  nextTopic = (___arguments.context.appData['currentTopics'])[(nextTopicIndex - 1)];
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', true);
  if (nextTopicIndex == (___arguments.context.appData['currentTopics']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == (___arguments.context.appData['lessons']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  ___arguments.context.pageData['title'] = (nextTopic['title']);
  ___arguments.context.pageData['breadcrumTopic'] = (nextTopic['title']);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(nextTopic['content'])))).setEncoding('utf8').send());
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (___arguments.context.pageData['lessonID']),'topicID': (nextTopic['objectId']) }));
  ___arguments.context.appData['expendLesson'] = false;
  ___arguments.context.appData['currentTopicIndex'] = nextTopicIndex;
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('51468ffb55571e86e2eea32f32bd7238')));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/lesson/components/934142127805cb0af8044a22d03bc4d1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var previousTopicIndex, nextTopic, currentHost, currentTopicIndex;


  currentHost = String((await ( async function (){ return window.location.href })()).slice(0, (await ( async function (){ return window.location.href })()).indexOf('.app/') + 1 + 4)) + String('api/files');
  currentTopicIndex = (___arguments.context.appData['currentTopicIndex']);
  previousTopicIndex = currentTopicIndex - 1;
  nextTopic = (___arguments.context.appData['currentTopics'])[(previousTopicIndex - 1)];
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0d7b6eb3c4fcdefab44608458da2727e', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('7b173a522ab61e0c780737640d3fc90b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', true);
  ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', true);
  if (previousTopicIndex == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('9f6ad9e61042bac5c183e94a31acbbbe', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('934142127805cb0af8044a22d03bc4d1', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == 1) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('f6df59a13d38913b7352dc0ed321c4bd', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('0aabd368706547f3965185ab7bb8ad71', false);
  }
  if ((___arguments.context.appData['currentLessonIndex']) == (___arguments.context.appData['lessons']).length) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e66835680cc2eed2379964fdab71fc52', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('e7fbc502988cf30c9a1cdaf4373dfb7b', false);
  }
  ___arguments.context.pageData['title'] = (nextTopic['title']);
  ___arguments.context.pageData['breadcrumTopic'] = (nextTopic['title']);
  ___arguments.context.pageData['lessonContent'] = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})((String(currentHost) + String(nextTopic['content'])))).setEncoding('utf8').send());
  ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('lesson', ({ 'courseID': (___arguments.context.pageData['courseID']),'lessonID': (___arguments.context.pageData['lessonID']),'topicID': (nextTopic['objectId']) }));
  ___arguments.context.appData['expendLesson'] = false;
  ___arguments.context.appData['currentTopicIndex'] = previousTopicIndex;
  ;await ( async function (component){ component.el.scrollIntoView() })(((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('51468ffb55571e86e2eea32f32bd7238')));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/login/components/85dae34486144e2efa2d934bfdddb172/bundle.js', [], () => ({
  /* content */

  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var user, error, password, username, stayLoggedIn, logging, buttonLogin;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function login() {
  try {
    user = (await Backendless.UserService.login( username, password, stayLoggedIn  ));

  } catch (error) {
    await setError(error);

  }
}

/**
 * Describe this function...
 */
async function setError(error) {
  ((function (componentUid) { return ___arguments.context.getComponentDataStoreByUid(componentUid) })('85dae34486144e2efa2d934bfdddb172'))['error'] = error;
}

/**
 * Describe this function...
 */
async function updateButtonLogin(logging) {
  buttonLogin = ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('db5546014b7940ad8771a98d85f83036'));
  buttonLogin['label'] = (logging ? 'Please wait...' : 'Login');
  buttonLogin['disabled'] = logging;
}


  await setError(null);
  username = (getObjectProperty(___arguments.context.dataModel, 'username'));
  password = (getObjectProperty(___arguments.context.dataModel, 'password'));
  stayLoggedIn = (getObjectProperty(___arguments.context.dataModel, 'stayLoggedIn'));
  if (username && password) {
    await updateButtonLogin(true);
    try {
      await login();
      if (user) {
        ___arguments.context.appData['currentUser'] = user;
        (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);
      }

    } catch (error) {
      if ((getObjectProperty(error, 'code')) == 3033) {
        await login();
      } else {
        await setError(error);
      }

    } finally {
      await updateButtonLogin(false);

    }
  } else {
    await setError((new Error('Username and Password must be filled')));
  }
  if (user) {
    ___arguments.context.appData['currentUser'] = user;
  }

  },
  /* handler:onSubmit *//* content */
}));

define('./pages/pricing/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var contactInfo, item, courses, packages, enabledFeatures, j, disabledFeatures, i, allFeatures;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('ed4b46ef5d53cf4248eb7b87a9f456e6'))['display'] = 'flex';
  packages = (await Backendless.Data.of('Package').find(Backendless.DataQueryBuilder.create().setRelationsDepth(1).setPageSize(10)));
  allFeatures = (await Backendless.Data.of('PackageFeature').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  for (var j_index in packages) {
    j = packages[j_index];
    disabledFeatures = [];
    enabledFeatures = [];
    var i_end = allFeatures.length;
    var i_inc = 1;
    if (1 > i_end) {
      i_inc = -i_inc;
    }
    for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
      if (!(((j['features']).map(item => item['name'])).includes(((allFeatures[(i - 1)])['name'])))) {
        addItemToList(disabledFeatures, (allFeatures[(i - 1)]));
      } else {
        addItemToList(enabledFeatures, (allFeatures[(i - 1)]));
      }
    }
    j['disabledFeatures'] = disabledFeatures;
    j['features'] = enabledFeatures;
  }
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('204daa41e5acdab16405899bd9f39ba2', packages);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('f1baed391956044b6b0135b2c0b4dbe8', (await Backendless.Data.of('FAQ').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10))));
  ___arguments.context.pageData['btnlabel'] = 'Subscribe';
  courses = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  ___arguments.context.appData['popularCourses'] = (await (async () => {
    async function callback (item) {


      return (item['isFeatured']);
    }

    const list = await Promise.all(courses.map(async source => ({
      source,
      value: await callback(source),
    })));

    const resultList = list.filter(item => item.value)

    return resultList.map(item => item.source)
  })());
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e75db1f7f7ac6149137c8b9a8aefdd77', (___arguments.context.appData['popularCourses']));
  contactInfo = (await Backendless.Data.of('ContactInfo').find(Backendless.DataQueryBuilder.create().setPageSize(10)))[0];
  ___arguments.context.pageData['address'] = (contactInfo['address']);
  ___arguments.context.pageData['phone'] = (contactInfo['phone']);
  ___arguments.context.pageData['contactEmail'] = (contactInfo['email']);
  ___arguments.context.pageData['socialLinks'] = (contactInfo['socialLinks']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('aa966a8dbbca954dc512805e3efbb25d', (___arguments.context.pageData['socialLinks']));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('ed4b46ef5d53cf4248eb7b87a9f456e6'))['display'] = 'none';

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/pricing/components/84f907bfbdd124929071a7b7db42cb35/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      if (((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open']) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = false;
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = true;
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/pricing/components/fa66b3d71af418b734b703c72c7b3895/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('home', null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/pricing/components/9a2976aca70b634cf4c363b46168d1ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var heightContent;


  heightContent = (await ( async function (typeSize, component){ return component && component.el ? component.el[`${typeSize}`] : null })('clientHeight', ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('821efe1a2ab1b97a9a56946e39f4722c'))));
  if ((((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('fa1e8192696e5a7f2bf1a2ca4322c3ca'))['el'])['classList'])['value']).indexOf('faq__icon--exp') + 1 == 0) {
    ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('fa1e8192696e5a7f2bf1a2ca4322c3ca'))['el'])['classList'])['value'] = 'material-icons MuiIcon-root bl-icon material-icons-round faq__icon--exp faq__icon ';
    ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('b5ff6ab8e6dc6fed5637aa46bda42f93'))['el'])['style'])['maxHeight'] = (String(heightContent) + String('px'));
  } else {
    ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('fa1e8192696e5a7f2bf1a2ca4322c3ca'))['el'])['classList'])['value'] = 'material-icons MuiIcon-root bl-icon material-icons-round faq__icon';
    ((((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('b5ff6ab8e6dc6fed5637aa46bda42f93'))['el'])['style'])['maxHeight'] = (String(0) + String('px'));
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/pricing/components/8feeb67a8526a9610161413cdcee27ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, userEmail;


  userEmail = (___arguments.context.pageData['email']);
  if (!userEmail) {
    ___arguments.context.pageData['message'] = 'Please enter your email';
    await new Promise(r => setTimeout(r, 250 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
    await new Promise(r => setTimeout(r, 2000 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    return false;
  }
  try {
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = true;
    ___arguments.context.pageData['btnlabel'] = 'Sending...';
    await Backendless.Data.of('Subscriber').save( ({ 'email': userEmail }) );
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8feeb67a8526a9610161413cdcee27ff', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ba74516dcece599c4cda9cdfb0e48b9', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('074c75b20d76e225b092fbbee555a627', true);

  } catch (error) {
    console.log(error);
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = false;
    ___arguments.context.pageData['btnlabel'] = 'Subscribe';
    if ((error['code']) == 8023) {
      ___arguments.context.pageData['message'] = 'Email address is not valid, please correct the email address and try again';
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
      await new Promise(r => setTimeout(r, 3500 || 0));
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    }

  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/pricing/components/96beb1e8c39f1ce10f673445106c7808/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.dataModel['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/pricing/components/9955e9238106ee628c22981e49fe222a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('mailto:') + String(___arguments.context.pageData['email'])), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/pricing/components/89fb35af24fe1da141df86e926182bc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var phoneToCall, i, phone;


  phone = (___arguments.context.pageData['phone']);
  phoneToCall = '';
  var i_end = phone.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (phone[(i - 1)] != '-') {
      phoneToCall = phoneToCall + phone[(i - 1)];
    }
  }
  ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('tel:') + String(phoneToCall)), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/pricing/components/fe7cf32f52df2a0eb646a052d3098b9d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((___arguments.context.dataModel['link']), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/pricing/components/6b263ab24803022b6b53c81fcb8f58c9/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  onTargetPathAssignment(___arguments) {
      return (String('https://maps.google.com/?q=') + String(___arguments.context.pageData['address']))

  },  
  /* handler:onTargetPathAssignment */
  /* content */
}))

define('./pages/privacypolicy/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async onEnter(___arguments) {
    var contactInfo, item, courses;


  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('d5b2d5ab8f0e2bb7c196596ebefe66b5'))['display'] = 'flex';
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e4f7ffddadbb4e0cbd75c231b49847fc', (await Backendless.Data.of('About').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10))));
  ___arguments.context.pageData['btnlabel'] = 'Subscribe';
  courses = (await Backendless.Data.of('Course').find(Backendless.DataQueryBuilder.create().setSortBy('order').setPageSize(10)));
  ___arguments.context.appData['popularCourses'] = (await (async () => {
    async function callback (item) {


      return (item['isFeatured']);
    }

    const list = await Promise.all(courses.map(async source => ({
      source,
      value: await callback(source),
    })));

    const resultList = list.filter(item => item.value)

    return resultList.map(item => item.source)
  })());
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('e75db1f7f7ac6149137c8b9a8aefdd77', (___arguments.context.appData['popularCourses']));
  contactInfo = (await Backendless.Data.of('ContactInfo').find(Backendless.DataQueryBuilder.create().setPageSize(10)))[0];
  ___arguments.context.pageData['address'] = (contactInfo['address']);
  ___arguments.context.pageData['phone'] = (contactInfo['phone']);
  ___arguments.context.pageData['contactEmail'] = (contactInfo['email']);
  ___arguments.context.pageData['socialLinks'] = (contactInfo['socialLinks']);
  ;(function (componentUid, listItems){ return ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('aa966a8dbbca954dc512805e3efbb25d', (___arguments.context.pageData['socialLinks']));
  ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('d5b2d5ab8f0e2bb7c196596ebefe66b5'))['display'] = 'none';

  },  
  /* handler:onEnter */
  /* content */
}))

define('./pages/privacypolicy/components/84f907bfbdd124929071a7b7db42cb35/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  onClick(___arguments) {
      if (((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open']) {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = false;
  } else {
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8921394d9d38d6ed2e067dead5833b95', true);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('b4e3077bf4de34510ed2b4c3cb69fb4e', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('bf4bdee7452889188b8d78327c42bf2d', true);
    ((function (componentUid){ return ___arguments.context.getComponentStyleByUid(componentUid) })('8921394d9d38d6ed2e067dead5833b95'))['open'] = true;
  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/privacypolicy/components/fa66b3d71af418b734b703c72c7b3895/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('home', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/privacypolicy/components/8feeb67a8526a9610161413cdcee27ff/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var error, userEmail;


  userEmail = (___arguments.context.pageData['email']);
  if (!userEmail) {
    ___arguments.context.pageData['message'] = 'Please enter your email';
    await new Promise(r => setTimeout(r, 250 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
    await new Promise(r => setTimeout(r, 2000 || 0));
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    return false;
  }
  try {
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = true;
    ___arguments.context.pageData['btnlabel'] = 'Sending...';
    await Backendless.Data.of('Subscriber').save( ({ 'email': userEmail }) );
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('8feeb67a8526a9610161413cdcee27ff', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('6ba74516dcece599c4cda9cdfb0e48b9', false);
    ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('074c75b20d76e225b092fbbee555a627', true);

  } catch (error) {
    console.log(error);
    ((function (componentUid){ return ___arguments.context.getComponentByUid(componentUid) })('8feeb67a8526a9610161413cdcee27ff'))['disabled'] = false;
    ___arguments.context.pageData['btnlabel'] = 'Subscribe';
    if ((error['code']) == 8023) {
      ___arguments.context.pageData['message'] = 'Email address is not valid, please correct the email address and try again';
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', true);
      await new Promise(r => setTimeout(r, 3500 || 0));
      ;(function (componentUid, visible){ (function(component){ component.display = !!(typeof visible === 'boolean' ? visible : !component.display ) })(___arguments.context.getComponentByUid(componentUid)) })('32f170648b166401bd6dab8410e392aa', false);
    }

  }

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/privacypolicy/components/96beb1e8c39f1ce10f673445106c7808/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('course', ({ 'ID': (___arguments.context.dataModel['objectId']) }));

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/privacypolicy/components/9955e9238106ee628c22981e49fe222a/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('mailto:') + String(___arguments.context.pageData['email'])), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/privacypolicy/components/89fb35af24fe1da141df86e926182bc6/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
    var phoneToCall, i, phone;


  phone = (___arguments.context.pageData['phone']);
  phoneToCall = '';
  var i_end = phone.length;
  var i_inc = 1;
  if (1 > i_end) {
    i_inc = -i_inc;
  }
  for (i = 1; i_inc >= 0 ? i <= i_end : i >= i_end; i += i_inc) {
    if (phone[(i - 1)] != '-') {
      phoneToCall = phoneToCall + phone[(i - 1)];
    }
  }
  ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((String('tel:') + String(phoneToCall)), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/privacypolicy/components/66d57075806ba64b37726d5600df2092/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (url, isExternal, params){ const targetUrl = `${url}?${BackendlessUI.QueryString.stringify(params)}`; isExternal ? window.open(targetUrl, '_blank') : window.location = targetUrl; })((___arguments.context.dataModel['link']), true, null);

  },  
  /* handler:onClick */
  /* content */
}))

define('./pages/privacypolicy/components/6b263ab24803022b6b53c81fcb8f58c9/bundle.js', [], () => ({
  /* content */
  /* handler:onTargetPathAssignment */
  onTargetPathAssignment(___arguments) {
      return (String('https://maps.google.com/?q=') + String(___arguments.context.pageData['address']))

  },  
  /* handler:onTargetPathAssignment */
  /* content */
}))

